import React from 'react';
import BasicWizardInput from "./BasicWizardInput";

const BankNameField = props => {
	const { t, onChange, validationErrors } = props;
	return (
		<BasicWizardInput
			validationErrors={validationErrors} 
			name="bankDetails.bankName" 
			onChange={onChange} 
			required={true} 
			placeholder={t('bankDetailsBankNameText')} 
			title={t('bankDetailsBankNameText')}
			error={false} />
	);
};

export default BankNameField;
