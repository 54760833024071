import React from 'react';
import BasicWizardSelect from "./BasicWizardSelect";

class FulfilmentComboFields extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showDeliveryOptions: false
		};

		this.handleInputChange = this.handleInputChange.bind(this);
	}

	get deliveryOptions() { return this.props.t('deliveryOptions', { returnObjects: true }) }

	handleInputChange(key, value) {
		if (key === 'fulfilment.type') {
			this.setState({
				showDeliveryOptions: value !== 'collectionOnly'
			});
		}

		this.props.onChange(key, value);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevState.showDeliveryOptions !== this.state.showDeliveryOptions) {
			if (!this.state.showDeliveryOptions) {
				const newOptions = this.deliveryOptions.reduce((s, opt) => ({ [opt.name]: undefined, ...s }), {})
				this.props.onChange(newOptions)
			}
		}
	}

	render() {
		const { t } = this.props;
		return (
			<React.Fragment>
				<BasicWizardSelect value=''
					validationErrors={this.props.validationErrors}
					name="fulfilment.type"
					onChange={this.handleInputChange}
					required={true}
					placeholder={t('pleaseSelectText')}
					title={t('fulfilmentTypeText')}
					options={t('fulfilmentOptions', {returnObjects:true})}
					error={false}
				/>
				{this.state.showDeliveryOptions && this.deliveryOptions.map(opt => (
					<BasicWizardSelect value=''
						name={opt.name}
						validationErrors={this.props.validationErrors}
						onChange={this.handleInputChange}
						required={true}
						placeholder={t('pleaseSelectText')}
						title={opt.title}
						options={opt.options}
						error={false}
					/>
				))}
			</React.Fragment>
		);
	}
}

export default FulfilmentComboFields;
