import React from 'react';
import withTenantConfiguration from '../../locales/withTenantConfiguration';

class TenantDiscontinuedPage extends React.Component {
	
	render () {
		const { t } = this.props;
		const TenantDiscontinued = () => 
			<div className="l-container section">
				<h2 className='section__sub-title--start'> {t('tenantdiscontinued_en')} </h2>
				<h2 className='section__sub-title--start'> {t('tenantdiscontinued_fr')} </h2>
			</div>;
		
		return (
			<div>
				{<TenantDiscontinued />}
			</div>
		);
	}
}

export default withTenantConfiguration('tenantdiscontinued', TenantDiscontinuedPage);
