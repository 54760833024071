import React from 'react';
import BitmapInfo from "../../Shared/BitmapInfo";

class UpsellSection extends React.Component {
	renderCashback = () => {
		const { t } = this.props;
		const cashbackText = t('upsellCashbackText');
		if (cashbackText === null || cashbackText === '') return null 

		return (
			<BitmapInfo
				image="/images/icons/cashback.svg"
				title={t('upsellCashbackTitle')}
			>
				{t('upsellCashbackText')}
			</BitmapInfo>
		);
	};

	render() {
		const { t } = this.props;

		return (
			<section id="upsell" className="section section--white">
				<div className="l-container">
					<h2 className="section__title">
						{t('upsellTitle')}
					</h2>

					<p className="section__sub-title">
						{t('upsellSubTitle')}
					</p>

					<div className="bitmap-info-list">
						<BitmapInfo
							image="/images/icons/daily-saving.svg"
							title={t('upsellDailySavingsTitle')}
						>
							{t('upsellDailySavingsText')}
						</BitmapInfo>

						{this.renderCashback()}

						<BitmapInfo
							image="/images/icons/innovative-tech.svg"
							title={t('upsellTechnologyTitle')}
						>
							{t('upsellTechnologyText')}
						</BitmapInfo>
					</div>
				</div>
			</section>
		);
	}
}

export default UpsellSection;
