import React from 'react'

const chain = (...fns) => (...args) => fns.forEach(f => f && f(...args)); 

class Accordion extends React.Component {
    state = {}
    render() { 
        const children = React.Children.map(this.props.children, item => {
            const onExpand = item.props.onExpand;
            return React.cloneElement(item, { 
                onExpand: chain(onExpand, () => this.setState({openItem: item})),
                isOpen: this.state.openItem === item
            })});

        return <div className="accordion">
            {children}
        </div>
    }
}

const AccordionItem = props => {
    const onToggle = e => e.target.open && props.onExpand && props.onExpand();
    return <details className="accordion__details" open={props.isOpen} onToggle={onToggle}>
            <summary className="accordion__summary">{props.summary}</summary>
            {props.children}
        </details>
};

export { Accordion, AccordionItem }
