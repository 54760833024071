import React from 'react';
import BasicWizardInput from "./BasicWizardInput";

const AccountHolderField = props => {
	const { t, onChange, validationErrors } = props;
	return (
		<BasicWizardInput
			validationErrors={validationErrors}
			name="bankDetails.accountHolderName" 
			onChange={onChange} 
			required={true}
			placeholder={t('bankDetailsAccountHolderNameText')}
			title={t('bankDetailsAccountHolderNameText')} 
			error={false} />
	);
};

export default AccountHolderField;
