import React from 'react';
import CompanyNameField from "./CompanyNameField";
import TaxNumberField from "./TaxNumberField";
import AccountHolderField from "./AccountHolderField";
import IbanField from "./IbanField";
import AddressStreetField from "./RestaurantAddressStreetField";
import AddressCityField from "./RestaurantAddressCityField";
import AddressPostalCodeField from "./RestaurantAddressPostalCodeField";
import MenuRequestedCuisines from "./MenuRequestedCuisinesField";
import FulfilmentComboFields from "./FulfilmentComboFields";
import BankNameField from "./BankNameField";
import IdNumberField from "./IdNumberField";
import CompanyNumberField from "./CompanyNumberField";
import IdentificationTypeField from "./IdentificationTypeField";
import NationalityComboField from "./NationalityComboField";
import RestaurantNameField from "./RestaurantNameField";
import OwnerFirstNameField from "./OwnerFirstNameField";
import OwnerLastNameField from "./OwnerLastNameField";
import OwnerTelephoneField from "./OwnerTelephoneField";
import OwnerEmailField from "./OwnerEmailField";
import CompanyAddressStreetField from './CompanyAddressStreetField';
import CompanyAddressPostalCodeField from './CompanyAddressPostalCodeField';
import CompanyAddressCityField from './CompanyAddressCityField';
import CompanyAddressProvinceField from './CompanyAddressProvinceField';
import CompanyDetails from './CompanyDetails';
import BusinessTypeField from './BusinessTypeField';

const SwitchField = props => {
	const { t, id, onChange, validationErrors, model } = props;
	const fields = {
		'tradingDetails.companyName': CompanyNameField,
		'tradingDetails.taxNumber': TaxNumberField,
		'bankDetails.accountHolderName': AccountHolderField,
		'bankDetails.iban': IbanField,
		'businessType': BusinessTypeField,
		'owner.firstName': OwnerFirstNameField,
		'owner.lastName': OwnerLastNameField,
		'owner.telephone': OwnerTelephoneField,
		'owner.email': OwnerEmailField,
		'address.city': AddressCityField,
		'address.postalCode': AddressPostalCodeField,
		'address.street': AddressStreetField,
		'menu.requestedCuisines': MenuRequestedCuisines,
		'fulfilment.combo': FulfilmentComboFields,
		'bankDetails.bankName': BankNameField,
		'tradingDetails.signatory.identification.number': IdNumberField,
		'tradingDetails.companyNumber': CompanyNumberField,
		'tradingDetails.signatory.identification.type': IdentificationTypeField,
		'tradingDetails.signatory.nationality': NationalityComboField,
		'restaurantName': RestaurantNameField,
		'tradingDetails.companyAddress.street': CompanyAddressStreetField,
		'tradingDetails.companyAddress.city': CompanyAddressCityField,
		'tradingDetails.companyAddress.postalCode': CompanyAddressPostalCodeField,
		'tradingDetails.companyAddress.province': CompanyAddressProvinceField,
		'tradingDetails.companyDetails': CompanyDetails
	};
	const MyNewComponent = fields[id];
	if (!MyNewComponent) {
		return (
			<div>{id} not found</div>
		);
	}
	return (
		<MyNewComponent validationErrors={validationErrors} model={model} onChange={onChange} t={t} />
	);
};

export default SwitchField;
