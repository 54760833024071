import React, {Component} from 'react';


class FooterDK extends Component {
	render() {
		return (
			<React.Fragment>
				<div className="links">
					<section>
						<input type="checkbox" id="toggle-cuisines"/>
						<label htmlFor="toggle-cuisines">
							<h3>
								Hvad har du lyst til?
								<svg>
									<use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-arrow"/>
								</svg>
							</h3>
						</label>
						<ul>
							<li><a href="https://www.just-eat.dk/takeaway">Takeaway</a></li>
							<li><a href="https://www.just-eat.dk/takeaway/italiensk">Pizza</a></li>
							<li><a href="https://www.just-eat.dk/takeaway/sushi/">Sushi</a></li>
							<li><a href="https://www.just-eat.dk/takeaway/indisk">Indisk</a></li>
							<li><a href="https://www.just-eat.dk/takeaway/kinesisk">Kinesisk</a></li>
							<li><a href="https://www.just-eat.dk/takeaway/amerikansk">Amerikansk &amp; burger</a></li>
							<li><a href="https://www.just-eat.dk/takeaway/premium-gourmet">Premium &amp; Gourmet</a>
							</li>
						</ul>
					</section>
					<section>
						<input type="checkbox" id="toggle-towns"/>
						<label htmlFor="toggle-towns">
							<h3>
								Find din by
								<svg>
									<use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-arrow"/>
								</svg>
							</h3>
						</label>
						<ul>
							<li><a href="https://www.just-eat.dk/takeaway/koebenhavn/">København</a></li>
							<li><a href="https://www.just-eat.dk/takeaway/roskilde">Roskilde</a></li>
							<li><a href="https://www.just-eat.dk/takeaway/aarhus/">Aarhus</a></li>
							<li><a href="https://www.just-eat.dk/takeaway/odense">Odense</a></li>
							<li><a href="https://www.just-eat.dk/takeaway/aalborg">Aalborg</a></li>
							<li><a href="https://www.just-eat.dk/takeaway/alle-byer/">Flere byer</a></li>
						</ul>
					</section>
					<section>
						<input type="checkbox" id="toggle-whoarewe"/>
						<label htmlFor="toggle-whoarewe">
							<h3>
								Om os
								<svg>
									<use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-arrow"/>
								</svg>
							</h3>
						</label>
						<ul>
							<li><a href="https://www.just-eat.dk/about" target="_self">Hvem er vi</a></li>
							<li><a href="https://www.just-eat.dk/privacypolicy" target="_self">Fortrolighedspolitik</a>
							</li>
							<li><a href="https://www.just-eat.dk/termsandconditions" target="_self">Vilkår og
								betingelser</a></li>
							<li><a href="https://www.just-eat.dk/cookies-policy" target="_self">Cookiepolitik</a></li>
							<li><a href="http://blog.just-eat.dk/" target="_blank">Just Eat Blog</a></li>
							<li><a href="https://www.just-eat.dk/takeaway/firmaaftale" target="_self">Få en
								firmaaftale</a></li>
							<li><a href="https://external-ordering.gavekortet.dk/justeat" target="_blank">Gavekort til
								Just Eat</a></li>
							<li><a href="https://www.just-eat.dk/takeaway/prisgaranti/" target="_blank">Prisgaranti</a>
							</li>
						</ul>
					</section>
					<section>
						<input type="checkbox" id="toggle-app"/>
						<label htmlFor="toggle-app">
							<h3>
								Mobile applications
								<svg>
									<use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-arrow"/>
								</svg>
							</h3>
						</label>
						<ul className="app-icons">
							<li>
								<a href="https://app.adjust.com/lgmmz6?utm_medium=internal&campaign=footer"
								   target="_blank">
									<img
										src="https://d11is265nvdnrc.cloudfront.net/assets/dist/img/icons/appstore/ios-v2.dk.svg"
										alt="Hent i App Store"/>
								</a>
							</li>
							<li>
								<a href="https://app.adjust.com/5sd9oz?utm_medium=internal&campaign=footer"
								   target="_blank">
									<img
										src="https://d11is265nvdnrc.cloudfront.net/assets/dist/img/icons/appstore/android-v2.dk.svg"
										alt="Hent i Google Play"/>
								</a>
							</li>
						</ul>
					</section>
				</div>
				<div className="info">
					<a href="https://www.facebook.com/JustEatDK/" className="social" tabIndex="74"
					   target="_blank">
						<img src="images/icons/social-facebook.svg"/>
					</a>
					<a href="https://twitter.com/justeatdk" className="social" tabIndex="77"
					   target="_blank">
						<img src="images/icons/social-twittter.svg"/>
					</a>
					<a href="http://blog.just-eat.dk/" className="social" tabIndex="76"
					   target="_blank">
						<img src="images/icons/rss-feed.svg"/>
					</a>
					
				</div>
			</React.Fragment>
		);
	}
}

export default FooterDK;
