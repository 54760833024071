import React from 'react';

import Footer from "../Footer";
import WebToLeadForm from "./WebToLeadPage/WebToLeadForm";
import withTenantConfiguration from '../../locales/withTenantConfiguration';

/**
 * This is a legacy component, this will be deprecated as all countries migrate to RSS
 */
class WebToLeadPage extends React.Component {
	render() {
		const { t } = this.props;

		return (
			<React.Fragment>
				<div className="hero">
					<div className="hero__image hero__image--signup-landing-page"/>
					<div className="hero__overlay"/>

					<div className="hero__text l-container">
						<div className="hero__text-col hero__text--center hero__text-col--spacing">
							<h1 className="hero__title">{t('heroTitle')}</h1>
							<h2 className="hero__sub-title">{t('heroSubTitle')}</h2>
						</div>
					</div>
				</div>

				<section id="webtolead" className="section ssection__contactection--white">
					<div className="l-container">
						<WebToLeadForm t={t} />
					</div>
				</section>

				{t('phoneNumberSales', {defaultValue: ''}) !== '' &&
					<section id="contact-us" className="section contact-us">
						<div className="l-container text-center section__contact">
							<span className="illustration"></span>
							<span className="content">
								<span className="section__title">{t('needHelp')}</span>
								<a className="btn__medium--secondary btn--wide" href={'tel:' + t('phoneNumberSales')}>{t('phoneNumberSalesPreText', {defaultValue: ''}) + ' ' + t('phoneNumberSales')}</a>
							</span>
						</div>
					</section>
				}
				
				<Footer />
			</React.Fragment>
		);
	}
}

export default withTenantConfiguration('signupwtl', WebToLeadPage);
