import React from 'react';
import { TenantContext } from '../TenantContext/TenantContext';
import createToggles from '../../data/toggles';
import { FeatureTogglesContext } from './FeatureTogglesContext';

export default class TenantFeatureTogglesProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toggles: null
        }
    }

    componentDidMount() {
        createToggles().then(toggles => this.setState({ toggles }));
    }

    render() {
        const {toggles} = this.state;
        return <TenantContext.Consumer>
            {({ selected }) => (
                <FeatureTogglesContext.Provider value={toggles ? toggles.forTenant(selected.tenant) : {}}>
                    {this.props.children}
                </FeatureTogglesContext.Provider>
            )}
        </TenantContext.Consumer>;
    }
}