import React, { Fragment }  from 'react';

import {Link} from "react-router-dom";
import Footer from "../Footer";
import withTenantConfiguration from '../../locales/withTenantConfiguration';

class DocumentThankYouPage extends React.Component {
	render() {
		const {t} = this.props;

		return (
			<Fragment>
				<div className="hero">
					<div className="hero__image hero__image--signup-landing-page"/>
					<div className="hero__overlay"/>
				</div>
				<main className="documents-thanks">
					<h2 className="documents-thanks__title">{t('title')}</h2>
					<p className="documents-thanks__description">
						{t('line1')}<br /><br />
						{t('line2')}<br /><br />
						{t('line3')}
					</p>
					<p className="documents-thanks__action">
						<Link to="/Benefits" className="btn__medium--primary btn-wide 	">{t('benefits')}</Link>
					</p>
				</main>
				<Footer ray={false}/>
			</Fragment>
		);
	}
}

export default withTenantConfiguration('documentthankyou', DocumentThankYouPage);
