import React from 'react';
import BasicWizardInput from "./BasicWizardInput";

export default  props => {
	const { t, onChange, validationErrors } = props;
	return (
		<BasicWizardInput
			validationErrors={validationErrors} 
			name="owner.emailAddress"
			onChange={onChange} 
			required={true}
			type="email"
			placeholder={t('ownerEmailText')} 
			title={t('ownerEmailText')} 
			error={false} />
	);
};
