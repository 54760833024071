import React from 'react';
import allTenants from "./allTenants";
import TenantProvider from "./TenantProvider";

export default class DevTenantProvider extends React.Component {
	constructor(props) {
		super(props);

		//Adds support for cypress testing.
		this.state = {
			tenant:  window.testTenant 
				|| window.sessionStorage.getItem("je-dev-tenant")
				|| this.props.defaultTenant,
			detect: true
		};

		this.switchTenant = this.switchTenant.bind(this);
		window.setTenant = this.switchTenant;
    }

    static defaultProps = {
        defaultTenant: allTenants[0].tenant,
		tenants: allTenants
    };

	switchTenant(tenant) {
		window.sessionStorage.setItem("je-dev-tenant", tenant)
		this.setState({
			tenant: tenant,
			detect: false
		})
	}
	
	render() {
		const containerStyle = {
			position: 'fixed',
			top: 0,
			left: 0,
			right: 0,
			textAlign: 'center',
			zIndex: '999',
			fontSize: '12px',
			opacity: '0.5'
		};

		const innerContainerStyle = {
			margin: '0px auto',
			width: 'auto',
			backgroundColor: '#af3a3a',
			display: 'inline-block'
		};
		
		const buttonStyle = {
			margin: '5px'
		};

		return (
			<React.Fragment>
				<div style={containerStyle}>
					<div style={innerContainerStyle}>
						{this.props.tenants.map(t =>
							<button key={t.tenant} style={buttonStyle} onClick={() => this.switchTenant(t.tenant)}>{t.tenant.toUpperCase()}</button>
						)}
					</div>
				</div>

				<TenantProvider {...this.props} detect={this.state.detect} defaultTenant={this.state.tenant} />
			</React.Fragment>
		);
	}
}