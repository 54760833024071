import React, {Component} from 'react';

import {connect} from "react-redux";
import {JL} from "jsnlog";

const mapStateToProps = state => ({
	location: state.router.location
});

class RouterLogger extends Component {
	componentDidMount() {
		this.logStateChange();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.location !== this.props.location) {
			this.logStateChange();
		}
	}
	
	logStateChange() {
		const event = {
			event: 'location_change',
			location: this.props.location
		};
		
		JL().info(event);
	}

	render() {
		return (
			<div/>
		);
	}
}


export default connect(mapStateToProps)(RouterLogger);
