import React from 'react';
import BasicWizardSelect from "./BasicWizardSelect";

const NationalityComboField = props => {
	const { t, onChange, validationErrors} = props;
	return (
		<BasicWizardSelect name="tradingDetails.signatory.nationality"
						   onChange={onChange}
						   validationErrors={validationErrors}
						   required={true}
						   placeholder={t('pleaseSelectText')}
						   title={t('nationalityText')}
						   options={t('nationalityOptions', { returnObjects: true })}
						   error={false}
		/>
	);
};

export default NationalityComboField;
