import React, { Fragment }  from 'react';

import StepsTracker from "./SignUpWizard/StepsTracker";
import agent from "../../agent";
import { withRouter } from "react-router-dom";
import Footer from "../Footer";
import SignUpDynamicForm from "./SignUpWizard/SignUpDynamicForm";
import withTenantConfiguration from '../../locales/withTenantConfiguration';

class SignUpWizard extends React.Component {
	constructor(props) {
		super(props);

		const model = JSON.parse(window.sessionStorage.getItem("signupModel") || "{}"); // TODO: redux

		this.state = {
			isLoading: false,
			fields: [],
			validationErrors: [],
			model: model
		};

		this.props.history.listen(() => this.reset());
	}

	componentDidMount() {
		this.reset();
	}

	reset() {
		const stepsData = this.props.t('steps', { returnObjects: true });

		const maxStep = stepsData.length;
		const routerStep = this.props.match ? this.props.match.params.step || "1" : "1";
		const rawStep = parseInt(routerStep);
		const currentStep = Math.max(Math.min(rawStep, maxStep), 1);
		const stepData = stepsData[currentStep - 1];
		const fields = stepData.fields || [];
		const restId = this.props.match.params.restId;

		if (rawStep !== currentStep) {
			this.props.history.replace(`/wizard/${restId}/step/${currentStep}`);
			return;
		}

		const state = {
			heading: stepData.heading,
			subheading: stepData.subheading,
			fields: fields,
			currentStep: currentStep,
			maxStep: maxStep,
			isLoading: false,
			hasErrors: false,
			errors: [],
			form: {},
			restaurantId: restId,
			validationErrors: []
		};

		this.setState(state);
	}

	updateRestaurant(restaurant) {
		this.setState({
			isLoading: true,
			validationErrors: []
		});

		restaurant.id = this.state.restaurantId;

		agent.Restaurant.update(restaurant)
			.then(r => {
				this.setState({ isLoading: false });
				this.next(r);
			})
			.catch(r => {
				console.error(r);
				this.setState({
					hasErrors: true,
					isLoading: false
				});
				if (r.status === 403) {
					this.props.history.push(`/`);
				}
				if (r.status === 400) {
					this.setState({
						validationErrors: r.response.body.Errors
					});
				}
			});
	}

	next(response) {
		if (this.state.currentStep !== this.state.maxStep) {
			if (response.applicationState !== "OpenApplication") {
				this.props.history.push(`/thankyou`);
			} else {
				this.props.history.push(`/wizard/${this.state.restaurantId}/step/${this.state.currentStep + 1}`);
			}
		} else {
			this.props.history.push(`/wizard/${this.state.restaurantId}/thankyou`);
		}
	}

	render() {
		const { t } = this.props;

		return (
			<Fragment>
				<div className="hero">
					<div className="hero__image hero__image--signup-landing-page"/>
					<div className="hero__overlay"/>
				</div>
				<main id="wizard" className="wizard wizard--spacious">
				<section className="wizard__sidebar">
					{this.state.maxStep > 1 &&
						<StepsTracker steps={this.state.maxStep} current={this.state.currentStep} t={t} />}
					<p className="helper-text">
						{this.state.heading}
					</p>
					<p className="lock-text">
						{this.state.subheading}
					</p>
				</section>
				<section className="wizard__content">
					<SignUpDynamicForm
						validationErrors={this.state.validationErrors}
						onSubmit={(restaurant) => this.updateRestaurant(restaurant)}
						isLoading={this.state.isLoading}
						fields={this.state.fields}
						previousModel={this.state.model}
						t={t} />
				</section>
			</main>
			<Footer ray={false}/>
		</Fragment>
		);
	}
}

export default withRouter(withTenantConfiguration('wizard', SignUpWizard));
