import React from 'react';
import BitmapInfo from "../../Shared/BitmapInfo";

class IdeasSection extends React.Component {
	render() {
		const { t } = this.props;

		return (
			<section id="ideas" className="section section--grey">
				<div className="l-container">
					<h2 className="section__title">
						{t('ideasTitle')}
					</h2>

					<p className="section__sub-title">
						{t('ideasText')}
					</p>

					<div className="bitmap-info-list">
						<BitmapInfo
							image="/images/icons/more-orders.svg"
							title={t('ideasOrdersTitle')}
						>
							{t('ideasOrdersText')}
						</BitmapInfo>

						<BitmapInfo
							image="/images/icons/more-control.svg"
							title={t('ideasControlTitle')}
						>
							{t('ideasControlText')}
						</BitmapInfo>

						<BitmapInfo
							image="/images/icons/more-value.svg"
							title={t('ideasValueTitle')}
						>
							{t('ideasValueText')}
						</BitmapInfo>
					</div>
				</div>
			</section>
		);
	}
}

export default IdeasSection;
