import React from 'react';
import BasicWizardInput from "./BasicWizardInput";

const RestaurantAddressPostalCodeField = props => {
	const { t, onChange, validationErrors, model } = props;
	const name = "address.postalCode";
	const initialValue = model && model.address && model.address.postalCode;

	return (
		<BasicWizardInput
			validationErrors={validationErrors}
			name={name}
			onChange={onChange}
			required={true} 
			placeholder={t('addressPostalCodeText')} 
			title={t('addressPostalCodeText')}
			error={false} 
			value={initialValue} />
	);
};

export default RestaurantAddressPostalCodeField;
