import React from 'react';
import BasicWizardSelect from "./BasicWizardSelect";

const MenuRequestedCuisines = props => {
	const {t, onChange, validationErrors} = props;
	const signupModel = JSON.parse(window.sessionStorage.getItem("signupModel") || "{}");
	return (
		<BasicWizardSelect name="menu.requestedCuisines.0" 
						onChange={onChange}
						validationErrors={validationErrors}
						required={true} 
						placeholder={t('pleaseSelectText')}
						title={signupModel.businessType !== 'Grocer' ? t('menuRequestedCuisinesText') : t('groceryTypeText')}
						options={signupModel.businessType !== 'Grocer' ? t('menuRequestedCuisinesOptions', { returnObjects: true }) : t('groceryTypeOptions', { returnObjects: true })}
						error={false} 
		/>
	)}

export default MenuRequestedCuisines;
