import ReactDOM from 'react-dom'
import React from 'react'

class Overlay extends React.Component{
    constructor(props) {
        super(props);
        this.node = document.createElement('div');
    }
    
    componentDidMount() {
        document.body.appendChild(this.node);
    }

    componentWillUnmount() { 
        document.body.removeChild(this.node);        
    }

    render() {
        const className = `overlay overlay__${this.props.visible? 'in':'out'}`

        if (this.props.visible)
        {
            document.body.classList.add('no-scroll');
        }
        else
        {
            document.body.classList.remove('no-scroll');
        }
        return ReactDOM.createPortal(
            <div className={className}>
                {this.props.visible && 
                    <React.Fragment>
                        <h1>{this.props.title} <button onClick={this.props.onClose} className="overlay__close"><i className="icon-Close bold-icon"/></button></h1>
                        <div>{this.props.children}</div>
                    </React.Fragment>
                }
            </div>,
            this.node
        )
    }
}

export default Overlay;
