import React from 'react';
import NavItem from "./NavItem";

class Navbar extends React.Component {
	onClick(e) { 
		if (e.target.matches('a,button')) this.navTrigger.checked = false;
	}

	render() {
		const { links } = this.props;
		return (
			<nav onClick={e => this.onClick(e)}>
				<input type="checkbox" id="nav-trigger" ref={el => this.navTrigger = el} />
				<label htmlFor="nav-trigger" >
					<span/>
				</label>
				<div>
					<ul>
						{links.map(link =>
							<NavItem link={link} key={link.title} />
						)}
					</ul>
				</div>
			</nav>
		);
	}
}
export default Navbar;
