import React from 'react';
import BasicWizardInput from "./BasicWizardInput";

const RestaurantNameField = props => {
	const { t, onChange, validationErrors} = props;
	return (
		<BasicWizardInput
			validationErrors={validationErrors} 
			name="name" 
			onChange={onChange} 
			required={true} 
			placeholder={t('restaurantNameText')} 
			title={t('restaurantNameText')}
			error={false} />
	);
};

export default RestaurantNameField;
