import React, { Fragment } from 'react';

import {Link} from "react-router-dom";
import Footer from "../Footer";
import withTenantConfiguration from '../../locales/withTenantConfiguration';

class SignUpWizardThankYou extends React.Component {
	render() {
		const {t} = this.props;
		const signupModel = JSON.parse(window.sessionStorage.getItem("signupModel") || "{}");
		return (
			<Fragment>
				<div className="hero">
					<div className="hero__image hero__image--signup-landing-page"/>
					<div className="hero__overlay"/>
				</div>
				<main className="wizard">
					<section className="wizard__sidebar">
						<h1>{t('sideTitle')}</h1>
						<div className="wizard__sidebarDescription">
							{t('sideSubtitle1')}
						</div>
						<div className="wizard__sidebarDescription">
							{t('sideSubtitle2')}
						</div>
						<div className="wizard__sidebarDescription">
							{t('sideHelp')}
						</div>
					</section>
					<section className="wizard__content">
						<div>
							<h2>{t('goToUploadHeading')}</h2>
							<p className="wizard__subTitle">

								{signupModel.businessType !== 'Grocer'?t('goToUploadMenuText'):t('goToUploadCatalogueText')}
							</p>

							<p className="wizard__action">
								<Link to={`/documents/${this.props.match.params.restId}`} className="btn__medium--primary">{signupModel.businessType !== 'Grocer'?t('goToUploadButtonMenuText'):t('goToUploadButtonProductCatalogueText')}</Link>
							</p>
						</div>
					</section>
				</main>
				<Footer ray={false}/>
			</Fragment>
		);
	}
}

export default withTenantConfiguration('wizardthankyou', SignUpWizardThankYou);
