import React from 'react';

import Footer from "../Footer";
import {Link} from "react-router-dom";
import BitmapInfo from "../Shared/BitmapInfo";
import withTenantConfiguration from '../../locales/withTenantConfiguration';

class PartnerCentre extends React.Component {
	render() {
		const { t } = this.props;

		return (
			<React.Fragment>
				<div className="hero">
					<div className="hero__image hero__image--partnercentre"/>
					<div className="hero__overlay"/>
					
					
					<div className="hero__text hero__text--center l-container">
						<div className="hero__text-col hero__text-col--spacing">
							<h1 className="hero__title">{t('heroTitle')}</h1>
							<h2 className="hero__sub-title">{t('heroSubTitle')}</h2>

							<Link to="/signup" className="btn__medium--primary btn__smallfont__mobile">{t('signUpButtonText')}</Link>
						</div>
					</div>
				</div>

				<section id="manage" className="section section--white">
					<div className="l-container">
						<h2 className="section__title">
							{t('manageSectionTitle')}
						</h2>

						<p className="section__sub-title">
							{t('manageSectionText')}
						</p>

						<div className="bitmap-info-list bitmap-info-list--two-col">
							<BitmapInfo
								image="/images/partner-centre/real-time.jpg"
								title={t('realTimeTitle')}
							>
								{t('realTimeText')}
							</BitmapInfo>
							<BitmapInfo
								image="/images/partner-centre/Across-device.jpg"
								title={t('crossDeviceTitle')}
							>
								{t('crossDeviceText')}
							</BitmapInfo>
							<BitmapInfo
								image="/images/partner-centre/Updates.jpg"
								title={t('updatesTitle')}
							>
								{t('updatesText')}
							</BitmapInfo>
							<BitmapInfo
								image="/images/partner-centre/Stay-informed.jpg"
								title={t('stayInformedTitle')}
							>
								{t('stayInformedText')}
							</BitmapInfo>

						</div>
					</div>
				</section>
				<Footer />
			</React.Fragment>
		);
	}
}

export default withTenantConfiguration('partnercentre', PartnerCentre);
