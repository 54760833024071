import React from 'react';
import track from 'react-tracking'
import Overlay from '../Shared/Overlay';
import { Accordion, AccordionItem } from '../Shared/Accordion'
import withTenantConfiguration from '../../locales/withTenantConfiguration';

class HelpButton extends React.Component {
    state = {
        visible: false
    };

    popup() { 
        this.props.tracking.trackEvent({eventAction: 'help'})
        this.setState({visible: true})
    }

    close() {
        this.setState({visible: false})
    }
    
    expand(question) {
        this.props.tracking.trackEvent({eventAction: 'view_question', eventLabel: question})
    }

	render() {
        const {t} = this.props;
        const faq = t('faq',  { returnObjects: true, interpolation: {escapeValue: false} }) || [];
        const items = faq.map(({question, answer}, index)  => 
            <AccordionItem summary={question} key={index} onExpand={() => this.expand(question)}>
                <p className="help-button__faqanswer" dangerouslySetInnerHTML={
								{__html: answer}
							}></p>
            </AccordionItem>)
        
		return (
            <React.Fragment>
                <a className="" onClick={() => this.popup()}>{this.props.title}</a>
                <Overlay visible={this.state.visible} title={t('title')}  onClose={() => this.close()}>
                   <Accordion>{items}</Accordion>
                </Overlay>
            </React.Fragment>
		)
	}
}

export default track({event: 'trackEvent', eventCategory: 'engagement'})(withTenantConfiguration(`helpButton`, HelpButton));
