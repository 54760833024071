import React from 'react';
import validation from "../../../../helpers/validation";

const BasicWizardInput = props => {
	const { required, placeholder, title, subtitle, onChange, name, validationErrors, type, value } = props;
	const error = validation.fieldHasErrors(name, validationErrors);

	let lblCls = 'label';
	if (error) lblCls += ' error';
	let titleCls = 'label__text';
	if (required) titleCls += ' label__text--required';
	return (
		<label className={lblCls}>
			<span className={titleCls}>{title}</span>
			{subtitle &&
				<small className="small small--grey">{subtitle}</small>}
			<input type={type||"text"}
				placeholder={placeholder}
				name={name}
				required={required}
				value={value}
				className="input input--block"
				onChange={(event) => onChange(event.target.name, event.target.value)} />
		</label>
	);
};

export default BasicWizardInput;
