import {Component} from 'react';
import withTenant from './TenantContext/withTenant';
import allTenants from './TenantContext/allTenants';

class CookieBanner extends Component {
	componentDidMount () {
		const tenant = allTenants.find(t => this.props.tenant === t.tenant);
		const script = document.createElement("script");
		script.src = `https://unpkg.com/@justeat/f-cookie-banner@3.7.1/dist/static/${tenant.cultures[0]}.js`;
		script.setAttribute('data-dt', 'cookie-banner-script')
		script.async = true;
		document.body.appendChild(script);
	}

	componentDidUnMount () {
		let element = document.querySelector('[data-dt="cookie-banner-script"]');
		element.parentNode.removeChild(element);
	}

	render() {
		return null;
	}
}

export default withTenant(CookieBanner);
