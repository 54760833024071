export default [
    {
        tenant: "uk",
        cultures: [ "en-GB" ]
    },
    {
        tenant: "au",
        cultures: [ "en-AU" ]
    },
    {
        tenant: "es",
        cultures: [ "es-ES" ]
    },
    {
        tenant: "it",
        cultures: [ "it-IT" ]
    },
    {
        tenant: "ie",
        cultures: [ "en-IE" ]
    },
    {
        tenant: "no",
        cultures: [ "nb-NO" ]
    },
    {
        tenant: "nz",
        cultures: [ "en-NZ" ]
    },
    {
        tenant: "dk",
        cultures: [ "da-DK" ]
    },
    {
        tenant: "ca",
        cultures: [ "en-CA", "fr-CA" ]
    },
    {
        tenant: "fr",
        cultures: [ "fr-FR" ]
    }
];