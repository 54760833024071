import React from 'react';
import BasicWizardInput from "./BasicWizardInput";

const CompanyNameField = props => {
	const { t, onChange, validationErrors} = props;
	return (
		<BasicWizardInput
			validationErrors={validationErrors} 
			name="tradingDetails.companyName" 
			onChange={onChange} 
			required={true} 
			placeholder={t('tradingDetailsCompanyNameText')} 
			title={t('tradingDetailsCompanyNameText')}
			subtitle={t('tradingDetailsCompanyNameExample')}
			error={false} />
	);
};

export default CompanyNameField;
