import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger'
import { routerMiddleware } from 'react-router-redux'
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

import createHistory from 'history/createBrowserHistory';

import reducers from '../reducers';
import array from './array';
import promise from './promise';


export const history = createHistory();

const logger = createLogger({
    collapsed: true,
    duration: true
});
const myRouterMiddleware = routerMiddleware(history);

const configureStore = () => {
    const middlewares = [myRouterMiddleware, thunk, promise, array, logger];
    const enhancer = applyMiddleware(...middlewares);

    return createStore(reducers, composeWithDevTools(enhancer));
};

export const store = configureStore();