import React from 'react';
import makeCancelable from '../helpers/makeCancelable';
import withTenant from '../components/TenantContext/withTenant';
import interpolate from '../helpers/interpolate';

export default function withTenantConfiguration(source, WrapperComponent) {
    class TenantConfigurationComponent extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
                hasLoaded: false,
                configuration: {}
            };

            this.t = this.t.bind(this);
        }

        componentDidMount() {
            this.addTenantConfigurationForComponent();
        }

        componentDidUpdate(prevProps) {
            const hasTenantChanged = prevProps.selected !== this.props.selected;

            if (hasTenantChanged) {
                this.addTenantConfigurationForComponent();
            }
        }

        componentWillUnmount() {
            if (this.import) {
                this.import.cancel();
            }
        }

        async addTenantConfigurationForComponent() {
            const selected = this.props;

            if (!selected) {
                return;
            }

            if (this.import) {
                this.import.cancel();
                this.setState({hasLoaded: false});
            }

            this.import = makeCancelable(import(`./${selected.tenant}/${source}.json`));

            try {
                const translations = await this.import.promise;

                this.setState({
                    hasLoaded: true,
                    configuration: translations
                });
            }
            catch(error) {
                if(error.isCanceled) {
                    return;
                }
                console.error(error);
            }
        }

        t(key, options) {
            const value = this.state.configuration[key];
            return typeof value === "string" ? interpolate(value, options) : value;
        }

        render() {
            const { hasLoaded } = this.state;
            return  hasLoaded ? <WrapperComponent {...this.props} config={this.state.configuration} t={this.t} /> : null;
        }
    }

    return withTenant(TenantConfigurationComponent);
}