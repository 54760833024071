import React, { Fragment }  from 'react';

import classNames from "classnames";
import FilePreview from "./DocumentUploadWizard/FilePreview";
import ProgressBar from "../Shared/ProgressBar";
import agent from "../../agent";
import Footer from "../Footer";
import withTenantConfiguration from '../../locales/withTenantConfiguration';

class DocumentUploadWizard extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {
			isUploading: false,
			uploadProgress: 0,
			uploadFileCurrent: 0,
			displayProgressSize: "0 KB",
			displayProgressTotalSize: "0 KB",
			restaurantId: props.match.params.restId,
			files: [],
			showPreview: false,
			previewSrc: '',
			previewIsPdf: false,
			hasAgreedTerms: false,
			showError: false,
			errorMessage: '',
			showTip: false,
			documentMetadata: []
		};
		
		this.hidePreview = this.hidePreview.bind(this);
		this.escFunction = this.escFunction.bind(this);
		this.toggleTooltip = this.toggleTooltip.bind(this);
		this.closeTooltip = this.closeTooltip.bind(this);
		this.uploadFiles = this.uploadFiles.bind(this);
		this.addFiles = this.addFiles.bind(this);
		this.removeFile = this.removeFile.bind(this);
		this.setAgreedTerms = this.setAgreedTerms.bind(this);
		this.previewFile = this.previewFile.bind(this);
		this.uploadNext = this.uploadNext.bind(this);
		this.handleUploadProgress = this.handleUploadProgress.bind(this);
	}

	componentDidMount(){
		document.addEventListener("keyup", this.escFunction, false);
	}
	
	componentWillUnmount(){
		document.removeEventListener("keyup", this.escFunction, false);
	}

	setAgreedTerms(termsAgreed) {
		this.setState({
			hasAgreedTerms: termsAgreed
		});
	}
	
	escFunction(event){
		if(event.keyCode === 27) {
			if (this.state.showPreview) {
				this.hidePreview();
			}
		}
	}
	
	hidePreview() {
		this.setState({
			showPreview: false
		});
	}

	toggleTooltip() {
		this.setState({
			showTooltip: !this.state.showTooltip
		});
	}

	closeTooltip() {
		this.setState({
			showTooltip: false
		});
	}

	uploadFiles() {
		const updates = {
			uploadProgress: 0,
			isUploading: true,
			displayProgressSize: '',
			displayProgressTotalSize: '',
			uploadFileCurrent: 0
		};
		this.state = {
			...this.state,
			...updates
		};
		this.setState(updates);
		this.uploadNext();
	}
	
	uploadNext() {
		const uploadFileCount = this.state.files.length;
		
		if (this.state.uploadFileCurrent < uploadFileCount) {
			const uploadFileCurrent = this.state.uploadFileCurrent + 1;
            console.log('state set');
			this.setState({
				uploadFileCurrent: uploadFileCurrent,
				showError: false
			});
			
			const file = this.state.files[uploadFileCurrent - 1];

			const reader = new FileReader();
			reader.addEventListener("load", () => {
				agent.Document
					.upload(this.state.restaurantId, 'menu', file.type, reader.result, this.handleUploadProgress)
					.then(response => {
						let documentMetadata = this.state.documentMetadata;
						documentMetadata.push({
							documentKey: response.documentKey,
							contentType: file.type
						});
						this.setState({
							documentMetadata: documentMetadata
						});
					    this.uploadNext();
					})
					.catch(error => {
						console.log(error);
						this.setState({
							showError: true,
							errorMessage: "Failed to upload documents",
							isUploading: false
						});
					});
			}, false);

			reader.readAsArrayBuffer(file);
		} else {
			let addDocumentsRequest = {
				documentMetadata: this.state.documentMetadata,
				documentType: 'Menu',
				TimestampVersion: new Date().toISOString()
			};

			agent.Document
				.addDocuments(this.state.restaurantId, addDocumentsRequest)
				.then(() => {
					this.props.history.push(`/documents/${this.props.match.params.restId}/thankyou`);
				})
				.catch(error => {
					console.log(error);
					this.setState({
						showError: true,
						errorMessage: "Failed to upload documents",
						isUploading: false
					});
				});
		}
	}
	
	handleUploadProgress(progressEvent) {
		this.setState({
			uploadProgress: Math.round((progressEvent.loaded / progressEvent.total) * 100),
			displayProgressSize: DocumentUploadWizard.bytesToSize(progressEvent.loaded),
			displayProgressTotalSize: DocumentUploadWizard.bytesToSize(progressEvent.total)
		});
	}

	previewFile(src, type) {
		this.setState({
			previewIsPdf: type === 'application/pdf',
			previewSrc: src,
			showPreview: true
		});
	}
	
	static bytesToSize(bytes) {
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
		if (bytes === 0) return '0 Byte';
		const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
		return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
	}


	addFiles (fileList) {
		this.setState({
			files: this.state.files.concat(Array.from(fileList))
		});
	}
	
	removeFile(fileToRemove) {
		this.state.files.splice(this.state.files.indexOf(fileToRemove), 1);
		this.setState({
			files: this.state.files
		});
	}
	
	render() {
		const {t} = this.props;
		
		const hasFiles = this.state.files.length > 0;
		const uploadFileCount = this.state.files.length;
		const canUpload = hasFiles && this.state.hasAgreedTerms && !this.state.isUploading;
		const signupModel = JSON.parse(window.sessionStorage.getItem("signupModel") || "{}");
		return (
			<Fragment>
				<div className="hero">
					<div className="hero__image hero__image--signup-landing-page"/>
					<div className="hero__overlay"/>
				</div>
				<main className="wizard" id="wizard">
					{this.state.showPreview &&
					<div className='large-preview'>
						<div className="preview-img">
							{!this.state.previewIsPdf &&
								<img src={this.state.previewSrc} />
							}
							
							{this.state.previewIsPdf &&
								<object data={this.state.previewSrc}
										type="application/pdf"
										width="100%">
									<svg className="no-pdf">
										<use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-icon-pdf"/>
									</svg>
								</object>
							}

							<svg className="close-btn" onClick={this.hidePreview}>
								<use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-close-btn"/>
							</svg>
						</div>
					</div>
					}
					<section className="wizard__sidebar">
						<h1>{signupModel.businessType !== 'Grocer'?t('uploadMenuTitle'):t('uploadProductCatalogueTitle')}</h1>
						<p className="uploadSidebar__description">
							{signupModel.businessType !== 'Grocer'?t('uploadMenuDescription'):t('uploadProductCatalogueDescription')}
						</p>
						<p className="uploadSidebar__link link" onClick={this.toggleTooltip}>
							{signupModel.businessType !== 'Grocer'?t('whyDoWeNeedThisLinkMenu'):t('whyDoWeNeedThisLinkProductCatalogue')}
						</p>

						{this.state.showTooltip &&
						<div className="ui-tip">
							<div className="ui-tip__content">
								{signupModel.businessType !== 'Grocer'?t('tooltipTextMenu'):t('tooltipTextProductCatalogue')}

								<div className="ui-tip__close" onClick={this.closeTooltip}/>
								<div className="ui-tip__pointer"/>
							</div>
						</div>
						}

						<p className="uploadSidebar__description">
							{signupModel.businessType !== 'Grocer'?t('highQualityTextMenu'):t('highQualityTextProductCatalogue')}
						</p>
					</section>
					<section className="wizard__content">
						{this.state.errorMessage && this.state.errorMessage.length > 0 &&
							<div id="errors" className="error">
								{this.state.errorMessage}
							</div>
						}
						
						<div className="upload-wrapper">
							<div className={classNames({'first-upload': !hasFiles, 'more-upload': hasFiles})}>
								{/*  File Preview List */}
								{hasFiles && !this.state.isUploading &&
									<div className="g g--stack--mid file-preview-list">
										{this.state.files.map(file => 
											<FilePreview file={file}
														key={`${file.name}${file.size}${file.lastModified}`}
														onDelete={this.removeFile}
														onPreview={this.previewFile}/>
										)}
									</div>
								}

								{/*  File drop area  */}
								{!this.state.isUploading &&
									<div className={classNames( "file-drop-area", { 'file-drop-area--cover': !hasFiles, 'file-drop-area--small': hasFiles })}>
										<input className="file-drop-area__file-input"
											type="file"
											id="file"
											ref="file"
											multiple
											accept="image/jpeg,image/png,application/pdf"
											onChange={e => this.addFiles(e.target.files)} />
	
										<div className="file-drop-area__icon">
										</div>
										<p className="file-drop-area__link">
											{t('uploadDropAreaLinkText')}
										</p>
										<p className="file-drop-area__text">
											{t('uploadDropAreaSubText')}
										</p>
									</div>
								}

								{/* Upoad progress */}
								{this.state.isUploading &&
								<div className="upload-progress">
									<ProgressBar percent={this.state.uploadProgress}/>
									<h3>{t('uploadingText')}</h3>
									<p>
										{t('uploadingProgressText', {
											current: this.state.uploadFileCurrent,
											total: uploadFileCount,
											progress: this.state.uploadProgress,
											bytes: this.state.displayProgressTotalSize
										})};
									</p>
								</div>
								}
							</div>
							
							<label className="checkbox terms">
								<input type="checkbox" name="acceptTerms" className="checkbox__input" onChange={e => this.setAgreedTerms(e.target.checked)} disabled={this.state.isUploading}/>
								<span className="checkbox__label" for="acceptTerms">
									<span>{signupModel.businessType !== 'Grocer'?t('checkboxConfirmTextMenu'):t('checkboxConfirmTextProductCatalogue')}</span>
								</span>
							</label>

							<button onClick={this.uploadFiles} disabled={!canUpload}
									className="btn__medium--primary btn--stretch">
								{t('uploadButtonText')}
							</button>
						</div>
					</section>
				</main>
				<Footer ray={false}/>
			</Fragment>
		);
	}
}

export default withTenantConfiguration('documents', DocumentUploadWizard);
