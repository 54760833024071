import React, {Component} from 'react';


class FooterIE extends Component {
	render() {
		return (
			<React.Fragment>
				<div className="links">
					<section>
						<input type="checkbox" id="toggle-cucine"/>
						<label htmlFor="toggle-cucine">
							<h3>
								Popular cuisines
								<svg>
									<use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-arrow"/>
								</svg>
							</h3>
						</label>
						<ul>
							<li><a href="https://www.just-eat.ie/takeaways/pizza/">Order Pizza</a></li>
							<li><a href="https://www.just-eat.ie/takeaways/chinese/">Order Chinese</a></li>
							<li><a href="https://www.just-eat.ie/takeaways/indian/">Order Indian</a></li>
							<li><a href="https://www.just-eat.ie/takeaways/mexican/">Order Mexican</a></li>
							<li><a href="https://www.just-eat.ie/takeaways/thai/">Order Thai</a></li>
						</ul>
					</section>
					<section>
						<input type="checkbox" id="toggle-citta"/>
						<label htmlFor="toggle-citta">
							<h3>
								Popular locations
								<svg>
									<use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-arrow"/>
								</svg>
							</h3>
						</label>
						<ul>
							<li><a href="https://www.just-eat.ie/takeaways/dublin-area/">Dublin</a></li>
							<li><a href="https://www.just-eat.ie/takeaways/limerick-city-centre/">Limerick</a></li>
							<li><a href="https://www.just-eat.ie/takeaways/cork-city-centre/">Cork</a></li>
							<li><a href="https://www.just-eat.ie/takeaways/galway-city-centre/">Galway</a></li>
							<li><a href="https://www.just-eat.ie/takeaways/waterford-city-centre/">Waterford</a></li>
						</ul>
					</section>
					<section>
						<input type="checkbox" id="toggle-whoarewe"/>
						<label htmlFor="toggle-whoarewe">
							<h3>
								About us
								<svg>
									<use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-arrow"/>
								</svg>
							</h3>
						</label>
						<ul>
							<li><a href="https://www.just-eat.ie/about" target="_self">About us</a></li>
							<li><a href="https://www.just-eat.ie/pricepromise" target="_self">Price Promise</a></li>
							<li><a href="https://www.just-eat.ie/help" target="_self">FAQs</a></li>
							<li><a href="https://www.just-eat.ie/takeaways" target="_self">Browse Takeaways</a></li>
							<li><a href="http://www.justeatshop.ie/gift-vouchers" target="_blank">Just Eat Gift Cards</a></li>
							<li><a href="https://www.just-eat.ie/blog" target="_blank">Just Eat Blog</a></li>
							<li><a href="http://mail.just-eat.ie/public/signup.jsp" target="_blank">Sign up for newsletter</a></li>
							<li><a href="https://partner-centre.just-eat.ie/help/privacy-policy" target="_self">Privacy Policy</a></li>
							<li><a href="https://www.just-eat.ie/termsandconditions" target="_self">Terms and Conditions</a></li>
							<li><a href="https://www.just-eat.ie/cookies-policy" target="_self">Cookie Policy</a></li>
							<li><a href="/complaint-handling" target="_self">Complaint Handling</a></li>
						</ul>
					</section>
					<section>
						<input type="checkbox" id="toggle-app"/>
						<label htmlFor="toggle-app">
							<h3>
								Mobile applications
								<svg>
									<use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-arrow"/>
								</svg>
							</h3>
						</label>
						<ul className="app-icons">
							<li>
								<a href="//app.adjust.com/14v0t5?utm_medium=internal&campaign=footer"
								   target="_blank">
									<img
										src="https://d2vkuayfhnkplp.cloudfront.net/assets/dist/img/icons/appstore/ios-v2.svg"
										alt="Download on the App Store"/>
								</a>
							</li>
							<li>
								<a href="//app.adjust.com/rb8vue?utm_medium=internal&campaign=footer"
								   target="_blank">
									<img
										src="https://d2vkuayfhnkplp.cloudfront.net/assets/dist/img/icons/appstore/android-v2.svg" 
										alt="Get it on Google Play"/>
								</a>
							</li>
						</ul>
					</section>
				</div>
				<div className="info">
					<a href="//www.facebook.com/JustEatIreland" className="social" tabIndex="74" target="_blank">
						<img src="images/icons/social-facebook.svg"/>
					</a>
					<a href="//www.twitter.com/JustEatIE" className="social" tabIndex="77" target="_blank">
						<img src="images/icons/social-twittter.svg"/>
					</a>
					<a href="//www.just-eat.ie/blog/feed" className="social" tabIndex="76" target="_blank">
						<img src="images/icons/rss-feed.svg"/>
					</a>
					
				</div>
			</React.Fragment>
		);
	}
}

export default FooterIE;
