import 'babel-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';

import {store, history} from './store/configureStore';
import App from "./components/App";

ReactDOM.render((
	<Provider store={store}>
        <ConnectedRouter history={history} onUpdate={() => window.scrollTo(0, 0)}>
            <Switch>
                <Route path="/" component={App} />
            </Switch>
        </ConnectedRouter>
	</Provider>

), document.getElementById('root'));

