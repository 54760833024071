import optimizely from '@optimizely/optimizely-sdk'
import { JL } from 'jsnlog';
import fetchUserId from '../userId'

//TODO: datafile also configured in preload in index.html
const datafileUrl = 'https://cdn.optimizely.com/datafiles/3az61gbAVxjwfq5nDmjMZc.json';

const features = [
];

function pushEvent(event, id, variation, description) {
    const experimentName = features.filter(({ testKey }) => testKey === id).map(({ testName }) => testName)[0];
    window.dataLayer.push({
        event: event,
        experiment: {
            id,
            name: experimentName,
            parent: 'N/A',
            version: '0.1',
            platform: 'optimizely_full_stack',
            variant: {
                name: variation
            },
            ...(description && { description })
        }
    });
}

function fetchDataFile(url) {
    const timeout = 1000, start = performance.now();
    return  new Promise((resolve, reject) => {
        fetch(url)
            .then(r => {
                JL().info({ event: 'datafile',  time: performance.now()-start });
                return r.status === 200 ? resolve(r.json()) : reject(r.text())
            })
            
        setTimeout(() => reject(new Error(`Timeout after ${timeout}ms`)), timeout);
    })
}

export default async function create() {
    try {
        const [datafile, userId] = await Promise.all([fetchDataFile(datafileUrl), fetchUserId()]);
        const instance = optimizely.createInstance({ datafile });

        return {
            getToggles: tenant => {
                const toggles = {};
                for (var { name, testKey } of features) {
                    const variation = instance.activate(testKey, userId, { Tenant: tenant });
                    if(variation) { 
                        toggles[name] = variation === 'variant';
                        pushEvent('trackExperimentV2', testKey, variation);
                    }
                }

                return toggles;
            },

            track: (tenant, testKey, event) => {
                const variation = instance.getVariation(testKey, userId, { Tenant: tenant });
                if (variation) {
                    pushEvent('trackExperimentEvent', testKey, variation, event);
                    instance.track(event, userId, { Tenant: tenant });
                }
            }
        };
    }
    catch (x) {
        console.warn(x);
        JL().warn("Error setting up experiments: " + x);
        return {
            getToggles: () => ({}),
            track: () => { }
        }
    }
}
