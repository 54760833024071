import React from 'react';
import validation from "../../../../helpers/validation";
import MaskedInput from 'react-maskedinput'

const IbanField = props => {
	const { t, onChange, validationErrors } = props;
	const internalOnChange = (key, value) => {
		onChange(key,  value.replace(/ /g, '').toUpperCase());
	};
	const name = 'bankDetails.iban';
	const error = validation.fieldHasErrors(name, validationErrors);
	
	const excludedChars = t('bankDetailsIbanMaskExcludedChars', { returnObjects: true });
	let formatCharacters = {};
	if (excludedChars !== 'bankDetailsIbanMaskExcludedChars') {
		for (let excludedChar of excludedChars) {
			formatCharacters[excludedChar] = {
				validate: (char)  => new RegExp(excludedChar, 'i').test(char),
				transform: (char) => `${excludedChar}`
			}
		}
	}

	let lblCls = 'label';
	if (error) lblCls += ' error';
	
	return (
		<label className={lblCls}>
			<span className='label__text label__text--required'>{t('bankDetailsIbanText')}</span>
			<small className="small small--grey">{t('bankDetailsIbanPlaceholder')}</small>
			<MaskedInput mask={t('bankDetailsIbanMask')}
						 name={name}
						 onChange={(event) => internalOnChange(event.target.name, event.target.value)}
						 type="text"
						 placeholder={t('bankDetailsIbanPlaceholder')}
						 required={true}
						 className="input input--block"
						 formatCharacters={formatCharacters} />

		</label>
	);
};

export default IbanField;
