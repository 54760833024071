import React from 'react';

import { Route } from 'react-router-dom';
import Benefits from "./Pages/Benefits";
import TenantDiscontinuedPage from "./Pages/TenantDiscontinuedPage";
import LandingPage from "./Pages/LandingPage";
import WTLThankYou from "./Pages/WTLThankYou";
import DocumentUploadWizard from "./Pages/DocumentUploadWizard";
import SignUpWizardThankYou from "./Pages/SignUpWizardThankYou";
import PartnerCentre from "./Pages/PartnerCentre";
import WebToLeadPage from "./Pages/WebToLeadPage";
import SignUpWizard from "./Pages/SignUpWizard";
import {Switch} from "react-router";
import RedirectWithParams from "./Shared/RedirectWithParams";
import DocumentThankYouPage from "./Pages/DocumentThankYouPage";
import FeatureToggles from './FeatureToggles';
import ComplaintHandling from './Pages/ComplaintHandling';

const AppRouter = (props) => {
	const tenant = props.tenant
    const WizardRoutes = () => <React.Fragment>
        <RedirectWithParams exact from={'/signup'} to={'/'} />
        <RedirectWithParams exact from={'/signup/:id/wizard'} to={'/wizard/:id'} />

        <Route exact path="/wizard/:restId" component={SignUpWizard}/>
        <Route exact path="/wizard/:restId/step/:step" component={SignUpWizard}/>

        <FeatureToggles.Swap feature="DocumentUpload"
                             on={<Route exact path="/wizard/:restId/thankyou" component={SignUpWizardThankYou}/>}
                             off={<Route exact path="/wizard/:restId/thankyou" component={WTLThankYou}/>} />

        <FeatureToggles.On  feature="DocumentUpload">
            <RedirectWithParams exact from={'/signup/:id/documents'} to={'/documents/:id'} />
            <Route exact path="/documents/:restId" component={DocumentUploadWizard}/>
            <Route exact path="/documents/:restId/thankyou" component={DocumentThankYouPage}/>
        </FeatureToggles.On>
    </React.Fragment>;

    const LegacyRoutes = () => <Route exact path="/signup" component={WebToLeadPage}/>;

	return (
		<Switch>
			{	tenant === 'fr' &&
				<Route path="*" component={ TenantDiscontinuedPage }/>
			}
			<Route exact path="/" component={tenant === 'fr'? TenantDiscontinuedPage : LandingPage}/>
			<Route exact path="/partnercentre" component={PartnerCentre}/>
			<Route exact path="/benefits" component={Benefits}/>
			<Route exact path="/thankyou" component={WTLThankYou}/>
            <Route exact path="/complaint-handling" component={ComplaintHandling}/>

            <FeatureToggles.Swap feature="Wizard"
                                 on={<WizardRoutes />}
                                 off={<LegacyRoutes />} />
		</Switch>
	);
};

export default AppRouter;
