import React from 'react';

const BasicWizardCheckbox = props => {
	const { title, onChange, checked, name } = props

	return <span className="checkbox">
		<input type="checkbox"
			className="checkbox__input"
			id={name}
			name={name}
			checked={checked}
			onChange={onChange} />
		<label htmlFor={name} className="checkbox__label">{title}</label>
	</span>
}

export default BasicWizardCheckbox
