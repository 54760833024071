const fieldValidationErrrors = (fieldName, validationErrors) => {
	if (!validationErrors) return [];

	const objectCasedKey = Object.keys(validationErrors).find(k => k.toLowerCase() === fieldName.toLowerCase());
	
	if (!objectCasedKey) return [];
	
	return validationErrors[objectCasedKey];
};

const fieldHasErrors = (fieldName, validationErrors) => {
	if (!validationErrors) return false;
	
	return Object.keys(validationErrors).map(k => k.toLowerCase()).indexOf(fieldName.toLowerCase()) !== -1;
};

export default {
	fieldValidationErrrors,
	fieldHasErrors
};
