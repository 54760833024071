import React from 'react';

class BasicWizardSelect extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			value: props.value || ''
		};

		this.handleInputChange = this.handleInputChange.bind(this);
	}

	handleInputChange(event) {
		this.setState({
			value: event.target.value
		});
		this.props.onChange(event.target.name, event.target.value);
		console.log(`${event.target.name} set to ${event.target.value}`)
	}

	render() {
		const { name, required, placeholder, title, error, options } = this.props;
		let lblCls = 'label';
		if (error) lblCls += ' error';
		let titleCls = 'label__text';
		if (required) titleCls += ' label__text--required';

		return (
			<label className={lblCls}>
				<span className={titleCls}>{title}</span>
				<select
					value={this.state.value}
					name={name}
					required="required"
					className="input input--block input-select"
					onChange={this.handleInputChange}>
					<option value=""
						disabled="disabled"
						hidden="hidden"
						className="placeholder">{placeholder}</option>
					{ options.map(([value, text]) =>
						<option value={value} key={value}>{text}</option>
					)}
				</select>
			</label>
		);
	}
};

export default BasicWizardSelect;
