import React from 'react';

class BitmapInfo extends React.Component {
	render() {
		return (
			<article className="bitmap-info">
				<img className="bitmap-info__image" alt={this.props.title} src={this.props.image} />

				<h3 className="bitmap-info__heading">{this.props.title}</h3>

				<p className="bitmap-info__text">
					{this.props.children}
				</p>
			</article>
		);
	}
}

export default BitmapInfo;
