import React from 'react';
import Footer from "../Footer";
import withTenantConfiguration from '../../locales/withTenantConfiguration';

/**
 * This is a legacy component, this will be deprecated as all countries migrate to RSS
 */
class WTLThankYou extends React.Component {
	render() {
		const { t } = this.props;

		return (
			<React.Fragment>
				<div className="hero">
					<div className="hero__image hero__image--signup-landing-page"/>
					<div className="hero__overlay"/>

					<div className="hero__text l-container">
						<div className="hero__text-col hero__text--center hero__text-col--spacing">
							<h1 className="hero__title">{t('heroTitle')}</h1>
							<h2 className="hero__sub-title">{t('heroSubTitle')}</h2>
						</div>
					</div>
				</div>
				
				<section id="thankyou" className="section section--white">
					<div className="l-container">
						<div className="text-center">
							<img className="thankyou__image" src="/images/icons/account-creation.svg"/>
							<a href={t('ourBlogLink')} className="btn__medium--primary">{t('ourBlogText')}</a>
						</div>	
					</div>
				</section>
				
				<Footer />
			</React.Fragment>
		);
	}
}

export default withTenantConfiguration('wtlthankyou', WTLThankYou);
