import React, {Component} from 'react';


class FooterCA extends Component {
	render() {
		return (
			<React.Fragment>
				<div className="links">
					<section>
						<input type="checkbox" id="toggle-cuisines"/>
						<label htmlFor="toggle-cuisines">
							<h3>
								Cuisines
								<svg>
									<use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-arrow"/>
								</svg>
							</h3>
						</label>
						<ul>
							<li><a href="https://www.just-eat.ca/ideas/">Ideas</a></li>
							<li><a href="https://www.just-eat.ca/delivery/chinese/">Chinese Food Delivery</a></li>
							<li><a href="https://www.just-eat.ca/delivery/pizza/">Pizza Delivery</a></li>
							<li><a href="https://www.just-eat.ca/delivery/sushi/">Sushi Delivery</a></li>
							<li><a href="https://www.just-eat.ca/delivery/thai/">Thai Food Delivery</a></li>
							<li><a href="https://www.just-eat.ca/delivery/indian/">Indian Food Delivery</a></li>
							<li><a href="https://www.just-eat.ca/delivery/greek/">Greek Food Delivery</a></li>
							<li><a href="https://www.just-eat.ca/delivery/gluten-free/">Gluten-free Food Delivery</a>
							</li>
							<li><a href="https://www.just-eat.ca/delivery/breakfast/">Breakfast Food Delivery</a></li>
							<li><a href="https://www.just-eat.ca/delivery/burgers/">Burger Food Delivery</a></li>
							<li><a href="https://www.just-eat.ca/delivery/bbq/">BBQ Delivery</a></li>
							<li><a href="https://www.just-eat.ca/delivery/mediterranean/">Mediterranean Delivery</a>
							</li>
							<li><a href="https://www.just-eat.ca/delivery/japanese/">Japanese Food Delivery</a></li>
							<li><a href="https://www.just-eat.ca/delivery/cuisine-types/">Take out Cuisines</a></li>
							<li><a href="https://www.just-eat.ca/delivery/">Delivery Restaurants</a></li>
						</ul>
					</section>
					<section>
						<input type="checkbox" id="toggle-towns"/>
						<label htmlFor="toggle-towns">
							<h3>
								Towns
								<svg>
									<use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-arrow"/>
								</svg>
							</h3>
						</label>
						<ul>
							<li><a href="https://www.just-eat.ca/delivery/toronto/">Toronto Food Delivery</a></li>
							<li><a href="https://www.just-eat.ca/delivery/calgary/">Calgary Food Delivery</a></li>
							<li><a href="https://www.just-eat.ca/delivery/waterloo/">Waterloo Food Delivery</a></li>
							<li><a href="https://www.just-eat.ca/delivery/kitchener/">Kitchener Food Delivery</a>
							</li>
							<li><a href="https://www.just-eat.ca/delivery/edmonton/ ">Edmonton Food Delivery</a>
							</li>
							<li><a href="https://www.just-eat.ca/delivery/halifax/">Halifax Food Delivery</a></li>
							<li><a href="https://www.just-eat.ca/delivery/mississauga/">Mississauga Delivery</a>
							</li>
							<li><a href="https://www.just-eat.ca/delivery/guelph/">Guelph Food Delivery</a></li>
							<li><a href="https://www.just-eat.ca/delivery/ottawa/">Ottawa Food Delivery</a></li>
							<li><a href="https://www.just-eat.ca/delivery/london/">London Food Delivery</a></li>
							<li><a href="https://www.just-eat.ca/delivery/vancouver/">Vancouver Food Delivery</a>
							</li>
							<li><a href="https://www.just-eat.ca/delivery/montreal/">Montréal Food Delivery</a></li>
							<li><a href="https://www.just-eat.ca/delivery/saskatoon/">Saskatoon Food Delivery</a>
							</li>
						</ul>
					</section>
					<section>
						<input type="checkbox" id="toggle-whoarewe"/>
						<label htmlFor="toggle-whoarewe">
							<h3>
								About us
								<svg>
									<use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-arrow"/>
								</svg>
							</h3>
						</label>
						<ul>
							<li><a href="https://www.just-eat.ca/pricepromise" target="_self">Price Promise</a></li>
							<li><a href="https://restaurants.just-eat.ca" target="_self">For Restaurants</a></li>
							<li><a href="https://www.just-eat.ca/help" target="_blank">Contact Us</a></li>
							<li><a href="https://www.just-eat.ca/about" target="_self">About Us</a></li>
							<li><a href="https://www.just-eat.ca/help" target="_self">FAQ's</a></li>
							<li><a href="https://www.just-eat.ca/blog" target="_self">Blog</a></li>
							<li><a href="https://www.just-eat.ca/privacy-policy" target="_self">Privacy Policy /
								Terms &amp; Conditions</a></li>
							<li><a href="https://www.just-eat.ca/jobs" target="_self">Career Opportunities</a></li>
						</ul>
					</section>
					<section>
						<input type="checkbox" id="toggle-app"/>
						<label htmlFor="toggle-app">
							<h3>
								Mobile applications
								<svg>
									<use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-arrow"/>
								</svg>
							</h3>
						</label>
						<ul className="app-icons">
							<li>
								<a href="https://app.adjust.com/v8spex?utm_medium=internal&campaign=footer" target="_blank">
									<img src="https://d3k6h8q47umtc.cloudfront.net/assets/dist/img/icons/appstore/ios-v2.svg" 
										 alt="Download on the App Store" />
								</a>
							</li>
							<li>
								<a href="https://app.adjust.com/f4selc?utm_medium=internal&campaign=footer" target="_blank">
									<img src="https://d3k6h8q47umtc.cloudfront.net/assets/dist/img/icons/appstore/android-v2.svg" 
										 alt="Get it on Google Play" />
								</a>
							</li>
						</ul>
					</section>
				</div>
				<div className="info">
					<a href="https://www.facebook.com/just.eat.canada" className="social" tabIndex="74"
					   target="_blank">
						<img src="images/icons/social-facebook.svg"/>
					</a>
					<a href="https://twitter.com/justeatca" className="social" tabIndex="77"
					   target="_blank">
						<img src="images/icons/social-twittter.svg"/>
					</a>
					<a href="https://www.just-eat.ca/blog/" className="social" tabIndex="76"
					   target="_blank">
						<img src="images/icons/rss-feed.svg"/>
					</a>
					
				</div>
			</React.Fragment>
		);
	}
}

export default FooterCA;
