import React from "react";

class ProgressBar extends React.Component {
	static defaultProps = {
		percent: 0
	};

	render() {
		return (
			<div className="progress">
				<div className="progress-bar" style={ {'width': this.props.percent + '%' } } />
			</div>
		);
	}
}

export default ProgressBar;
