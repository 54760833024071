import React from "react";
import {FeatureTogglesContext} from './FeatureTogglesContext';

export default class FeatureToggles {
    static Swap = props => (
        <FeatureTogglesContext.Consumer>
            {(config) => config[props.feature] !== undefined && 
                    React.cloneElement(config[props.feature] ? props.on : props.off)
            }
        </FeatureTogglesContext.Consumer>
    );

    static On = props => (
        <FeatureTogglesContext.Consumer>
            {(config) => config[props.feature] &&
                props.children
            }
        </FeatureTogglesContext.Consumer>
    );

    static Off = props => (
        <FeatureTogglesContext.Consumer>
            {(config) => !config[props.feature] &&
                props.children
            }
        </FeatureTogglesContext.Consumer>
    );
}
