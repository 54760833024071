import React, {Component} from 'react';

class FooterUK extends Component {
	render() {
		return (
			<React.Fragment>
				<div className="links">
					<section>
						<input type="checkbox" id="toggle-cs"/>
						<label htmlFor="toggle-cs">
							<h3>
								Customer service <svg>
								<use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-arrow"/>
							</svg>
							</h3>
						</label>
						<ul>
							<li><a href="//www.just-eat.co.uk/help">Contact us</a></li>
							<li><a href="//www.just-eat.co.uk/account/login">Log in</a></li>
							<li><a href="//www.just-eat.co.uk/account/register">Sign up</a></li>
							<li><a href="//www.just-eat.co.uk/blog">Blog</a></li>
							<li><a href="//www.just-eat.co.uk/apps">Mobile Apps</a></li>
							<li><a href="//www.just-eat.co.uk/suggest-a-restaurant">Suggest a restaurant</a></li>
							<li><a href="//www.just-eat.co.uk/help">Help</a></li>
							<li><a href="//www.just-eat.co.uk/member/updateuserinfo">My account</a></li>
						</ul>
					</section>
					<section>
						<input type="checkbox" id="toggle-cuisines"/>
						<label htmlFor="toggle-cuisines">
							<h3>
								Top cuisines <svg>
								<use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-arrow"/>
							</svg>
							</h3>
						</label>
						<ul>
							<li><a href="//www.just-eat.co.uk/chinese-takeaways">Chinese</a></li>
							<li><a href="//www.just-eat.co.uk/indian-takeaways">Indian</a></li>
							<li><a href="//www.just-eat.co.uk/italian-takeaways">Italian</a></li>
							<li><a href="//www.just-eat.co.uk/japanese-takeaways">Japanese</a></li>
							<li><a href="//www.just-eat.co.uk/pizza-delivery">Pizza delivery</a></li>
							<li><a href="//www.just-eat.co.uk/cuisine">View all cuisines</a></li>
						</ul>
					</section>
					<section>
						<input type="checkbox" id="toggle-locations"/>
						<label htmlFor="toggle-locations">
							<h3>
								Locations <svg>
								<use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-arrow"/>
							</svg>
							</h3>
						</label>
						<ul>
							<li><a href="//www.just-eat.co.uk/birmingham-takeaway">Birmingham</a></li>
							<li><a href="//www.just-eat.co.uk/cardiff-takeaway">Cardiff</a></li>
							<li><a href="//www.just-eat.co.uk/glasgow-takeaway">Glasgow</a></li>
							<li><a href="//www.just-eat.co.uk/leeds-takeaway">Leeds</a></li>
							<li><a href="//www.just-eat.co.uk/manchester-takeaway">Manchester</a></li>
							<li><a href="//www.just-eat.co.uk/takeaway">View all locations</a></li>
						</ul>
					</section>
					<section>
						<input type="checkbox" id="toggle-about"/>
						<label htmlFor="toggle-about">
							<h3>
								A bit more about us <svg>
								<use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-arrow"/>
							</svg>
							</h3>
						</label>
						<ul>
							<li><a href="//restaurants.just-eat.co.uk">Restaurant sign up</a></li>
							<li><a href="//www.just-eat.co.uk/pricepromise">Price Promise</a></li>
							<li><a href="//www.just-eat.co.uk/cookiespolicy">How do we use cookies?</a></li>
							<li><a href="//www.just-eat.co.uk/privacy-policy">Privacy Policy / T&amp;Cs</a></li>
							<li><a href="//www.just-eat.co.uk/about">About us</a></li>
							<li><a href="//www.just-eat.com">Company website</a></li>
							<li><a href="//careers.just-eat.com/">Careers</a></li>
						</ul>
					</section>
				</div>
				<div className="info">
					<a href="//www.facebook.com/justeat" className="social" tabIndex="74" target="_blank">
						<img src="images/icons/social-facebook.svg"/>
					</a>
					<a href="//www.twitter.com/justeatuk" className="social" tabIndex="77" target="_blank">
						<img src="images/icons/social-twittter.svg"/>
					</a>
					<a href="//www.just-eat.co.uk/blog/feed" className="social" tabIndex="76" target="_blank">
						<img src="images/icons/rss-feed.svg"/>
					</a>
					
				</div>
			</React.Fragment>

		);
	}
}

export default FooterUK;
