import React from "react";
import { TenantContext } from "./TenantContext";
import allTenants from "./allTenants";

export default class TenantProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: this.props.detect ? this.computeTenant() : this.defaultTenant(),
            tenants: this.props.tenants
        };

        if(this.props.onSetTenant) {
            this.props.onSetTenant(this.state.selected.tenant, this.state.selected.cultures);
        }
    }

    static defaultProps = {
        tenants: allTenants,
        defaultTenant: allTenants[0].tenant,
        detect: true
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.defaultTenant !== this.props.defaultTenant) {
            const state = {
                selected: this.props.detect ? this.computeTenant() : this.defaultTenant()
            };
            this.setState(state);

            if(this.props.onSetTenant) {
                this.props.onSetTenant(state.selected.tenant, state.selected.cultures);
            }
        }

        return null;
    }

    computeTenant() {
        const host = window.location.hostname;
        let { tenants } = this.props;

        let tenant = tenants.find(t => this.hostIsOkForTenant(host, t.tenant));

        if (!tenant) {
            tenant = this.defaultTenant();
        }

        return tenant;
    }

    defaultTenant() {
        return this.props.tenants.find(t => t.tenant.toLowerCase() === this.props.defaultTenant.toLowerCase());
    }

    hostIsOkForTenant(host, tenant) {
        host = host.toLowerCase();
        tenant = tenant.toLowerCase();

        return host.endsWith(tenant) || host.startsWith(`${tenant}-`);
    }

    render() {
        const { selected } = this.state;
        return selected ? <TenantContext.Provider value={this.state}>
            {this.props.children}
        </TenantContext.Provider> : null;
    }
}