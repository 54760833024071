import React from 'react';
import BasicWizardInput from "./BasicWizardInput";

const CompanyAddressStreetField = props => {
	const { t, onChange, validationErrors } = props;
	const name = "tradingDetails.companyAddress.lines.0"
	return (
		<BasicWizardInput name={name}
						  validationErrors={validationErrors}
						  onChange={onChange} 
						  required={true} 
						  placeholder={t('companyAddressStreetText')}
						  title={t('companyAddressStreetText')} 
						  error={false} />
	);
};

export default CompanyAddressStreetField;
