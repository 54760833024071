import React from 'react';

import Navbar from "./Nav/Navbar";
import withTenantConfiguration from '../locales/withTenantConfiguration';

class Header extends React.Component {
	render() {
		const { t } = this.props;

		return (
			<header className="header header--sticky">
				<div>
					<a href="/">
						<img src="/images/logo-justeat-light.svg" className="logo" />
						<div className="hideIe">
							<div className="noSvg" />
						</div>
					</a>
					
					<Navbar links={t('links', { returnObjects: true })} />
				</div>
			</header>
		);
	}
}

export default withTenantConfiguration('header', Header);
