import React from 'react';
import BasicWizardInput from "./BasicWizardInput";

const CompanyAddressCityField = props => {
	const { t, onChange, validationErrors } = props;
	const name = "tradingDetails.companyAddress.city"
	return (
		<BasicWizardInput
			validationErrors={validationErrors} 
			name={name}
			onChange={onChange} 
			required={true}
			placeholder={t('companyAddressCityText')} 
			title={t('companyAddressCityText')} 
			error={false} />
	);
};

export default CompanyAddressCityField;
