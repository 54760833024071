import React from 'react';
import BasicWizardSelect from "./BasicWizardSelect";

const IdentificationTypeField = props => {
	const {t, onChange, validationErrors} = props;
	return (
		<BasicWizardSelect name="tradingDetails.signatory.identification.type" 
						   onChange={onChange}
						   validationErrors={validationErrors}
						   required={true} 
						   placeholder={t('pleaseSelectText')}
						   title={t('identificationTypeText')}
						   options={t('identificationTypeOptions', { returnObjects: true })}
						   error={false} 
		/>
	);
};

export default IdentificationTypeField;
