import React from 'react';
import BasicWizardSelect from "./BasicWizardSelect";

const CompanyTypeField = props => {
	const { t, onChange, validationErrors} = props;

	return (
		<BasicWizardSelect value=''
						   validationErrors={validationErrors}
						   name="tradingDetails.type"
						   onChange={onChange}
						   required={true}
						   placeholder={t('tradingDetailsTypeText')}
						   title={t('tradingDetailsTypeText')}
						   options={t('tradingDetailsTypeOptions', {returnObjects: true})}
		/>
	);
};

export default CompanyTypeField;
