import React from 'react';
import BasicWizardInput from "./BasicWizardInput";

const CompanyNumberField = props => {
	const { t, onChange, validationErrors } = props;
	return (
		<BasicWizardInput
			validationErrors={validationErrors} 
			name="tradingDetails.companyNumber" 
			onChange={onChange} 
			required={true} 
			placeholder={t('tradingDetailsCompanyNumberText')} 
			title={t('tradingDetailsCompanyNumberText')}
			error={false} />
	);
};

export default CompanyNumberField;
