import React, {Component} from 'react';

import FooterIT from "./Footer/FooterIT";
import FooterUK from "./Footer/FooterUK";
import FooterIE from "./Footer/FooterIE";
import FooterCA from "./Footer/FooterCA";
import FooterES from "./Footer/FooterES";
import FooterDK from "./Footer/FooterDK";
import FooterFR from "./Footer/FooterFR";
import CookieBanner from "./CookieBanner"
import withTenant from './TenantContext/withTenant';

class Footer extends Component {    
	
	render() {
		return (
			<React.Fragment>
				<footer className="footer">
					{this.props.tenant === 'it' &&
						<FooterIT />
					}
					{this.props.tenant === 'uk' &&
						<FooterUK />
					}
					{this.props.tenant === 'ie' &&
						<FooterIE />
					}
					{this.props.tenant === 'ca' &&
						<FooterCA />
					}
					{this.props.tenant === 'es' &&
						<FooterES />
					}
					{this.props.tenant === 'dk' &&
						<FooterDK />
					}
					{this.props.tenant === 'fr' &&
						<FooterFR />
					}
				</footer>
				<CookieBanner/>
			</React.Fragment>
		);
	}
}

export default withTenant(Footer);
