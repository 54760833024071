import React from 'react';
import { Link } from 'react-router-dom'
import HelpButton from './HelpButton'

class NavItem extends React.Component {
	render() {
		const {type, title, href, children} = this.props.link;
		const isDropDown = (children !== undefined);

		function ItemByType() {
			switch (type) {
				case 'remote':
					return <a href={href}>{title}</a>;
				case 'help-button':
					return <HelpButton title={title}/>;
				default:
					return <Link to={href}>{title}</Link>;
				}
		}

		function DropDown() { 
			return <React.Fragment>
				<p><span>{title}</span></p>
				<ul className="c-nav-popoverList">
					{children.map(link =>
						<NavItem link={link} key={link.title}/>
					)}
				</ul>
			</React.Fragment>
		}

		return (
			<li className="has-sublist">
				{isDropDown ?  <DropDown/> : <ItemByType/>}
			</li>
		);
	}
}

export default NavItem;
