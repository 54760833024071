import React from 'react';
import BasicWizardInput from "./BasicWizardInput";

export default  props => {
	const { t, onChange, validationErrors } = props;
	return (
		<BasicWizardInput
			validationErrors={validationErrors} 
			name="owner.familyName"
			onChange={onChange} 
			required={true}
			placeholder={t('ownerLastNameText')} 
			title={t('ownerLastNameText')} 
			error={false} />
	);
};
