import React from 'react';

import Footer from "../Footer";
import Link from "react-router-dom/es/Link";
import withTenantConfiguration from '../../locales/withTenantConfiguration';

class Benefits extends React.Component {
	render() {
		const { t } = this.props;

		const hasPhoneContact = t('hasPhoneContact', { returnObjects: true }) === true;
		const hasContactDescription = t('contactDescription', { returnObjects: true });

		return (
			<React.Fragment>
				<div className="hero">
					<div className="hero__image hero__image--benefits"/>
					<div className="hero__overlay"/>
					
					
					<div className="hero__text hero__text--center l-container">
						<div className="hero__text-col hero__text-col--spacing">
							<h1 className="hero__title">{t('heroTitle')}</h1>
							<h2 className="hero__sub-title">{t('heroSubTitle')}</h2>
						</div>
					</div>
				</div>

				<section id="whyus" className="section section--white">
					<div className="l-container">
						<h2 className="section__title">
							{t('whyusSectionTitle')}
						</h2>

						<div className="g g--stack g--gutter">
							<div className="g-col g-span4--mid text-center">
								<h2 className="section__title--sub-heading">{t('partnerCount')}</h2>
								<p className="section__sub-title text-center">{t('partnerCountText')}</p>
							</div>
							<div className="g-col g-span4--mid text-center">
								<h2 className="section__title--sub-heading">{t('customerCount')}</h2>
								<p className="section__sub-title ext-center">{t('customerCountText')}</p>
							</div>
							<div className="g-col g-span4--mid text-center">
								<h2 className="section__title--sub-heading">{t('orderCount')}</h2>
								<p className="section__sub-title text-center">{t('orderCountText')}</p>
							</div>
						</div>
					</div>
				</section>
				
				<section id="getting-started" className="section section--white">
					<div className="l-container">
						<h2 className="section__title">
							{t('getStartedSectionTitle')}
						</h2>
						<div className="g g--stack g--gutter text-center">
							<div className="g-col g-span6--mid">
								<h2 className="">{t('spreadWordTitle')}</h2>
								<p className="bitmap-info__text">{t('spreadWordText')}</p>
								
								<h2 className="">{t('reviewTitle')}</h2>
								<p className="bitmap-info__text">{t('reviewText')}</p>
							</div>
							<div className="g-col g-span6--mid">
								<h2 className="">{t('partnerCentreTitle')}</h2>
								<p className="bitmap-info__text">{t('partnerCentreText')}</p>

								<img className="getting-started__image" src="images/justeat-chef-with-bags.svg"/>
							</div>
						</div>
					</div>
				</section>

				<section id="areyouready" className="section">
					<div className="l-container">
						<h2 className="section__title">
							{t('areYouReadySectionTitle')}
						</h2>
						
						{hasPhoneContact &&
							<div className="contact">
								<a href={'tel:' + t('signByPhoneNumber')} className="btn__medium--secondary btn--wide">{t('signByPhoneButtonText')}</a>
								<Link to="/signup" className="btn__medium--primary btn--wide">{t('signUpButtonText')}</Link>
							</div>
						}

						{!hasPhoneContact &&
							<div className="contact">
								<Link to="/signup" className="btn__medium--primary btn--wide">{t('signUpButtonText')}</Link>
							</div>
						}

						{hasContactDescription &&
							<div className='contact__description' dangerouslySetInnerHTML={
								{__html: t('contactDescription', {interpolation: {escapeValue: false}})}
							}>
							</div>
						}
					</div>
				</section>
				
				<Footer />
			</React.Fragment>
		);
	}
}

export default withTenantConfiguration('benefits', Benefits);
