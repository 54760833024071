import React from 'react';
import SwitchField from "./Fields/SwitchField";
import dot from "dot-object";

class SignUpDynamicForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			form: null
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.submitData = this.submitData.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.fields !== prevProps.fields) {
			this.setState({
				form: null
			});
		}
	}

	handleInputChange(keyOrValues, value) {
		const newValues = typeof keyOrValues === "string" ? { [keyOrValues]: value } : keyOrValues;

		const removeNestedProperty = (currentResult, [k, v]) => {
			const nestedPropertyOverridden = Object.keys(newValues).find(key => k.indexOf(key + '.') === 0)
			return nestedPropertyOverridden || v === undefined ?
				currentResult :
				{ ...currentResult, [k]: v }
		}

		const allValues = {
			...this.state.form,
			...newValues
		}

		const newForm = Object.entries(allValues).reduce(removeNestedProperty, {})

		this.setState({ form: newForm })
	}

	submitData(event) {
		const restaurant = dot.object(this.state.form);

		if (this.props.onSubmit) {
			this.props.onSubmit(restaurant);
		}

		event.preventDefault();
	}

	renderSubmitButtonText() {
		const { t, isLoading, continueText } = this.props;

		if (isLoading){
			return (<span className="icon--loader"></span>);
		}
		else {
			return continueText || t('continue');
		}
	}


	render() {
		const { t, isLoading, fields, previousModel } = this.props;

		return (
			<form id="wizard-form" onSubmit={this.submitData}>
				<fieldset disabled={isLoading}>
					{fields.map((fieldName, i) =>
						<SwitchField validationErrors={this.props.validationErrors}
							key={i}
							model={previousModel}
							onChange={this.handleInputChange}
							id={fieldName}
							t={t} />
					)}
				</fieldset>
				<div className="label label__center">
				<button type="submit" className="btn__medium--primary" disabled={isLoading} >
					{ this.renderSubmitButtonText() }
				</button>
				</div>
			</form>
		);
	}
}

export default SignUpDynamicForm;
