import React from 'react';
import { TenantContext } from "./TenantContext";

export default function withTenant(WrappedComponent) {
    return (props) => {
        return (
            <TenantContext.Consumer>
                {({selected}) => <WrappedComponent {...selected} {...props} />}
            </TenantContext.Consumer>
        );
    };
}