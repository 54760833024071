import React from 'react';

class QuoteSection extends React.Component {
	render() {
		const { t } = this.props;

		return (
			<section id="quotes" className="section section--white signup-quote">
				<div className="l-container">
					<blockquote className="quote">
						<p className="quote__text">{t('quoteText')}</p>
						<cite className="quote__cite">{t('quoteCite')}</cite>
					</blockquote>
				</div>
			</section>
		);
	}
}

export default QuoteSection;
