import React from "react";

class FilePreview extends React.Component {
	static defaultProps = {
		onDelete: null,
		onPreview: null
	};

	constructor(props) {
		super(props);

		this.state = {
			src: '',
			isLoading: true,
			hasSrc: false,
			fileType: 'image'
		};

		this.emitPreview = this.emitPreview.bind(this);
		this.emitDelete = this.emitDelete.bind(this);
		this.load = this.load.bind(this);
	}

	componentDidMount() {
		this.load();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.file !== prevProps.file) {
			this.load();
		}
	}

	load() {
		const file = this.props.file;

		if (file) {
			const fileType = file.type;
			const isPdf = fileType === 'application/pdf';
			const isImage = fileType === 'image/png' || fileType === 'image/jpeg';
			const src = URL.createObjectURL(file);

			this.setState({
				file: file,
				fileType: fileType,
				src: src,
				isImage: isImage,
				isPdf: isPdf
			});

			if (isImage) {
				const img = document.createElement('img');
				img.src = src;
				img.onload = () => {
					this.setState({
						hasSrc: true,
						isLoading: false
					});
				};
				img.onerror = (e) => {
					this.setState({
						hasSrc: false,
						isLoading: false
					});
					console.warn(e);
				};
			} else if (isPdf) {
				this.setState({
					hasSrc: true,
					isLoading: false
				});
			} else {
				this.setState({
					isLoading: false
				});
			}
		}
	}

	emitPreview() {
		if(this.props.onPreview) {
			this.props.onPreview(this.state.src, this.state.fileType);
		}
	}

	emitDelete() {
		if(this.props.onDelete) {
			this.props.onDelete(this.state.file);
		}
	}

	render() {
		const showImage = !this.state.isLoading && this.state.hasSrc && this.state.isImage;

		return (
			<div className="g-col g-span3">
				<div className="file-preview">
					{showImage &&
					<img className="file-preview__img" onClick={this.emitPreview} src={this.state.src}/>
					}

					{this.state.isPdf &&
					<object data={this.state.src}
							type="application/pdf"
							width="100%"
							height="100%"
							className="file-preview__pdf">
						<svg className="no-pdf">
							<use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-icon-pdf"/>
						</svg>
					</object>
					}
					{this.state.isLoading &&
					<div className="loader spin">
						<svg>
							<use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-loading"/>
						</svg>
					</div>
					}
					{!this.state.isLoading &&
					<div className="file-preview__controls">
						<img src="/images/icons/trash.svg" onClick={this.emitDelete}/>
					</div>
					}
				</div>
			</div>
		);
	}
}

export default FilePreview;
