export default async function fetchUserId() {
    let ga = window.ga;
    let count = 0;
    while (!ga || typeof ga.getAll !== 'function') {
        if (count++ > 100) throw new Error("Google Analytics not initialised");
        await new Promise(resolve => setTimeout(resolve, 10));
        ga = window.ga;
    }

    return ga.getAll()[0].get('clientId');
}