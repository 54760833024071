import React from 'react';
import CompanyTypeField from "./CompanyTypeField";
import BasicWizardCheckbox from "./BasicWizardCheckbox";
import SwitchField from './SwitchField';


class CompanyDetails extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
			requireName: false,
            sameAddress: true,
            showAddressForm: false,
            fields: [],
            addressFields: []
		};

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(key, value) {
        if (key === 'tradingDetails.type') {
            const config = this.props.t("companyTypes").find(function(element) {
                return element.type === value;
            });

            const addressFields = config.showAddressForm ? this.props.t("tradingAddressFields") : []; 

			this.setState({
                fields: config.fields,
                showAddressForm: config.showAddressForm,
                addressFields: addressFields
			});
		}

		this.props.onChange(key, value);
    }

    showField(fieldName) {
        if(!this.state.fields || !this.state.fields.length) {
            return false;
        }

        const found = this.state.fields.findIndex(function(element) {
            return element === fieldName;
        })

        return found > -1;
    }
    
    sameAddressChanged(checked) {
		this.setState({ sameAddress: checked })
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.sameAddress !== this.state.sameAddress ||
            prevState.showAddressForm !== this.state.showAddressForm) {

			const newValues = { "copyRestaurantAddressToCompanyAddress": this.state.sameAddress };
			
			if (this.state.sameAddress) {
				newValues["tradingDetails.companyAddress"] = undefined
			}

			this.props.onChange(newValues)
		}
	}
    
    render() {
        const {t} = this.props;
        const fields = this.state.fields;
        const showAddressForm = this.state.showAddressForm;
        const addressFields = this.state.addressFields;

        return (
            <React.Fragment>
                <CompanyTypeField
					validationErrors={this.props.validationErrors}
					onChange={this.handleInputChange} 
					t={t} />

                <React.Fragment>
                    {fields.map((fieldName, i) =>
                        <SwitchField 
                            validationErrors={this.props.validationErrors}
                            key={i}
                            onChange={this.props.onChange}
                            id={fieldName}
                            t={t} />
                    )}
                </React.Fragment>

                {showAddressForm && 
                    <React.Fragment>                       
                        <label className="label">
                            <span className="label__text label__text--required">{t("tradingDetailsCompanyAddress")}</span>
                        </label>

                        <BasicWizardCheckbox
                            title={t("tradingDetailsSameAddress")}
                            name="sameAddress"
                            checked={this.state.sameAddress}
                            onChange={e => this.sameAddressChanged(e.target.checked)} />
                        
                            {this.state.sameAddress || addressFields.map((fieldName, i) => 
                                <SwitchField validationErrors={this.props.validationErrors}
                                    key={i}
                                    onChange={this.props.onChange}
                                    id={fieldName}
                                    t={t} />
                            )}
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

export default CompanyDetails;