import React from 'react';
import classNames from "classnames";

class StepsTracker extends React.Component {
	render() {
		const { steps, current, t } = this.props;

		return (
			<div className="step-tracker">
				<span class="step-tracker__title">{t('step')}</span>
				{[...Array(steps).keys()].map(index => 
					<span key={index} className={classNames(
						"step-tracker__step",
						{
							'step-tracker__step--selected': ((index + 1) === current)
						}
					)}>
							{index + 1}
					</span>
				)}
				
			</div>
		);
	}
}

export default StepsTracker;
