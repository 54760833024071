import React from 'react';
import agent from "../../../agent";
import { withRouter } from "react-router";
import SignUpDynamicForm from '../SignUpWizard/SignUpDynamicForm';
import withTenant from '../../../components/TenantContext/withTenant';
import withTenantConfiguration from '../../../locales/withTenantConfiguration';

const Form = withTenantConfiguration('wizard', SignUpDynamicForm);

class GetStartedForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			hasErrors: false,
			errors: [],
			model: {}
		};

		this.createRestaurant = this.createRestaurant.bind(this);
		this.next = this.next.bind(this);
	}

	createRestaurant(restaurant) {
		this.setState({
			isLoading: true,
			errors: [],
		});

		agent.Restaurant.create(restaurant)
			.then(r => {
				this.setState({ isLoading: false });
				this.next(r);
				window.sessionStorage.setItem('signupModel', JSON.stringify(restaurant)); //TODO: redux
			})
			.catch(r => {
				this.setState({
					hasErrors: true,
					isLoading: false
				});
				if (r.status === 400) {
					this.setState({
						errors: r.response.body.Errors
					});
				}
				console.error(r);
			});
	}

	next(result) {
		if (result.applicationState !== "OpenApplication") {
			this.props.history.push(`/thankyou`);
		} else {
			this.props.history.push(`/wizard/${result.restaurantId}`);
		}
	}

	render() {
		const { t } = this.props;
		const isLoading = this.state.isLoading;
		const errors = this.state.errors;
		const step = t("wizardStep", { returnObjects: true });

		return (
			<div className="panel panel--grey panel--stack panel--max-400">
				<h1 className="panel__title panel__title--center">{step.heading}</h1>
				<Form isLoading={isLoading}
					fields={step.fields}
					continueText={step.submit}
					validationErrors={errors}
					onSubmit={this.createRestaurant} />
			</div>
		);
	}
}

export default withRouter(withTenant(GetStartedForm));
