import React from 'react';

import GetStartedForm from "./LandingPage/GetStartedForm";
import IdeasSection from "./LandingPage/IdeasSection";
import UpsellSection from "./LandingPage/UpsellSection";
import QuoteSection from "./LandingPage/QuoteSection";
import StepsSection from "./LandingPage/StepsSection";
import Footer from "../Footer";
import Link from "react-router-dom/es/Link";
import FeatureToggles from '../FeatureToggles';
import withTenantConfiguration from '../../locales/withTenantConfiguration';

class LandingPage extends React.Component {

	render() {
		const { t } = this.props;

        const LandingPageForm = () => <div className="hero__text hero__text--two-col">

            <div className="hero__text-col hero__text-col--spacing">
                <h1 className="hero__title">{t('heroTitle')}</h1>
                <h2 className="hero__sub-title">{t('heroSubTitle')}</h2>
            </div>

            <div className="hero__text-col hero__text-col--spacing">
                <GetStartedForm t={t}/>
            </div>
        </div>;

        const LandingPageHero = () => <div className="hero__text l-container">
            <div className="hero__text-col hero__text--center hero__text-col--spacing">
                <h1 className="hero__title">{t('heroTitle')}</h1>
                <h2 className="hero__sub-title">{t('heroSubTitle')}</h2>

                <Link to="/signup" className="btn__medium--primary">{t('signUpButtonText')}</Link>
            </div>
        </div>;

		return (
			<React.Fragment>
				<div className="hero">
					<div className="hero__image hero__image--signup-landing-page"/>
					<div className="hero__overlay"/>

                    <FeatureToggles.Swap feature="Wizard"
                                         off={<LandingPageHero />}
                                         on={<LandingPageForm />} />
				</div>
				
				<UpsellSection t={t} />
				<IdeasSection t={t} />
				<QuoteSection t={t} />
				<StepsSection t={t} />
				
				<Footer ray={false}/>
			</React.Fragment>
		);
	}
}

export default withTenantConfiguration("landingpage", LandingPage);
