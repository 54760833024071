import React from 'react';
import BasicWizardInput from "./BasicWizardInput";

const TaxNumberField = props => {
	const { t, onChange, validationErrors } = props;
	return (
		<BasicWizardInput
			validationErrors={validationErrors}
			name="tradingDetails.taxNumber"
			onChange={onChange}
			required={true} 
			placeholder={t('tradingDetailsTaxNumberText')}
			title={t('tradingDetailsTaxNumberText')}
			error={false} />
	);
};

export default TaxNumberField;
