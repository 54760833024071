import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';

const superagent = superagentPromise(_superagent, global.Promise);

const API_ROOT = '/';

const responseBody = res => res.body;
const storeToken = res => {
	Token.set(res.headers['je-token']);
	return res;
};

const requests = {
	del: url =>
		superagent.del(`${API_ROOT}${url}`).then(responseBody),
	get: url =>
		superagent.get(`${API_ROOT}${url}`).then(responseBody),
	put: (url, body) =>
		superagent.put(`${API_ROOT}${url}`, body).then(responseBody),
	patch: (url, body) =>
		superagent.patch(`${API_ROOT}${url}`, body).then(responseBody),
	post: (url, body) =>
		superagent.post(`${API_ROOT}${url}`, body).then(responseBody)
};

const Config = {
	get: () => requests.get(`api/config`)
};


const omitId = restaurant => Object.assign({}, restaurant, { id: undefined });
const Restaurant = {
	create: restaurant =>
		superagent.post(`${API_ROOT}api/restaurants`, restaurant).then(storeToken).then(responseBody),
	update: restaurant => 
		superagent
			.patch(`${API_ROOT}api/restaurants/${restaurant.id}`, omitId(restaurant))
			.set('Authorization', `Bearer ${Token.get()}`)
			.then(responseBody)
};

const Document = {
	upload: (restaurantId, documentType, fileType, fileBytes, progressCallback) =>
		superagent
			.post(`${API_ROOT}api/restaurants/${restaurantId}/documents`)
			.set('Content-Type', fileType)
			.set('Je-Ops-Document-Type', documentType)
			.set('Authorization', `Bearer ${Token.get()}`)
			.then(({body: { documentKey, href, headers, method }}) => 
				superagent(method, href)
					.set(headers || {})
					.on('progress', progressCallback)
					.send(fileBytes)
					.then(responseBody => ({ documentKey }))),
	addDocuments: (restaurantId, requestBody) =>
		superagent
			.post(`${API_ROOT}api/restaurants/${restaurantId}/documents/add`)
			.set('Authorization', `Bearer ${Token.get()}`)
			.send(requestBody)
			.then(responseBody)
};

const Token = {
	get: () =>
		window.localStorage.getItem("rss-create-token"),
	set: (token) =>
		window.localStorage.setItem("rss-create-token", token)
};

export default {
	Config,
	Restaurant,
	Document
};
