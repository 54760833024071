import React from 'react';
import BasicWizardInput from "./BasicWizardInput";

const CompanyAddressProvinceField = props => {
	const { t, onChange, validationErrors } = props;
	const name = "tradingDetails.companyAddress.province"
	return (
		<BasicWizardInput
			validationErrors={validationErrors} 
			name={name}
			onChange={onChange} 
			required={true}
			placeholder={t('companyAddressProvinceText')} 
			title={t('companyAddressProvinceText')} 
			error={false} />
	);
};

export default CompanyAddressProvinceField;
