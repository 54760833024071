import React from 'react';
import BasicWizardInput from "./BasicWizardInput";

const IdNumberField = props => {
	const { t, onChange, validationErrors } = props;
	return (
		<BasicWizardInput
			validationErrors={validationErrors} 
			name="tradingDetails.signatory.identification.number" 
			onChange={onChange} 
			required={true} 
			placeholder={t('tradingDetailsSignatoryIdNumberText')} 
			title={t('tradingDetailsSignatoryIdNumberText')}
			error={false} />
	);
};

export default IdNumberField;
