import React from 'react';
import BasicWizardInput from "./BasicWizardInput";

const CompanyAddressPostalCodeField = props => {
	const { t, onChange, validationErrors } = props;
	const name = "tradingDetails.companyAddress.postalCode";

	return (
		<BasicWizardInput
			validationErrors={validationErrors}
			name={name}
			onChange={onChange}
			required={true} 
			placeholder={t('companyAddressPostalCodeText')} 
			title={t('companyAddressPostalCodeText')}
			error={false} />
	);
};

export default CompanyAddressPostalCodeField;
