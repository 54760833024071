import React, {Component} from 'react';


class FooterES extends Component {
	render() {
		return (
			<React.Fragment>
				<div className="links">
					<section>
						<input type="checkbox" id="toggle-cuisines"/>
						<label htmlFor="toggle-cuisines">
							<h3>
								Tipos de cocina
								<svg>
									<use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-arrow"/>
								</svg>
							</h3>
						</label>
						<ul>
							<li><a href="https://www.just-eat.es/adomicilio/pizza">Pizza a domicilio</a></li>
							<li><a href="https://www.just-eat.es/adomicilio/kebab">Kebab a domicilio</a></li>
							<li><a href="https://www.just-eat.es/adomicilio/comida-china">Comida china a domicilio</a>
							</li>
							<li><a href="https://www.just-eat.es/adomicilio/sushi">Sushi a domicilio</a></li>
							<li><a href="https://www.just-eat.es/adomicilio/hamburguesas/">Hamburguesas a domicilio</a>
							</li>
							<li><a href="https://www.just-eat.es/adomicilio/tipos-de-comida/">Mas tipos de cocina</a>
							</li>
						</ul>
					</section>
					<section>
						<input type="checkbox" id="toggle-towns"/>
						<label htmlFor="toggle-towns">
							<h3>
								Ciudades
								<svg>
									<use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-arrow"/>
								</svg>
							</h3>
						</label>
						<ul>
							<li><a href="https://www.just-eat.es/adomicilio/madrid">Madrid</a></li>
							<li><a href="https://www.just-eat.es/adomicilio/barcelona">Barcelona</a></li>
							<li><a href="https://www.just-eat.es/adomicilio/valencia">Valencia</a></li>
							<li><a href="https://www.just-eat.es/adomicilio/zaragoza">Zaragoza</a></li>
							<li><a href="https://www.just-eat.es/adomicilio/palmas-de-gran-canaria">Las Palmas</a></li>
							<li><a href="https://www.just-eat.es/adomicilio/localidades">Más ciudades</a></li>
						</ul>
					</section>
					<section>
						<input type="checkbox" id="toggle-whoarewe"/>
						<label htmlFor="toggle-whoarewe">
							<h3>
								Sobre nosotros
								<svg>
									<use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-arrow"/>
								</svg>
							</h3>
						</label>
						<ul>
							<li><a href="https://www.just-eat.es/about" target="_self">Quienes somos</a></li>
							<li><a href="https://www.just-eat.es/help" target="_self">Ayuda</a></li>
							<li><a href="https://www.just-eat.es/adomicilio/garantia-de-precio/" target="_blank">Garantía
								de precio</a></li>
							<li><a href="https://www.just-eat.es/privacypolicy" target="_self">Política de
								Privacidad</a></li>
							<li><a href="https://www.just-eat.es/termsandconditions" target="_self">Términos y
								Condiciones</a></li>
							<li><a href="https://www.just-eat.es/cookies-policy" target="_self">Política de Cookies</a>
							</li>
							<li><a href="http://www.just-eat.es/blog" target="_blank">Nuestro blog</a></li>
							<li><a href="https://www.just-eat.es/blog/descuento-5e-just-eat/" target="_blank">5€ de
								descuento</a></li>
							<li><a href="https://www.just-eat.es/adomicilio/afiliacion" target="_blank">Programa de
								Afiliación</a></li>
						</ul>
					</section>
					<section>
						<input type="checkbox" id="toggle-app"/>
						<label htmlFor="toggle-app">
							<h3>
								Mobile applications
								<svg>
									<use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-arrow"/>
								</svg>
							</h3>
						</label>
						<ul className="app-icons">
							<li>
								<a href="https://app.adjust.com/gxu4mb?utm_medium=internal&campaign=footer"
								   target="_blank">
									<img
										src="https://d3rdvw6dv0rmzb.cloudfront.net/assets/dist/img/icons/appstore/ios-v2.es.svg"
										alt="Consíguelo en el App Store"/>
								</a>
							</li>
							<li>
								<a href="https://app.adjust.com/f78ds2?utm_medium=internal&campaign=footer"
								   target="_blank">
									<img
										src="https://d3rdvw6dv0rmzb.cloudfront.net/assets/dist/img/icons/appstore/android-v2.es.svg"
										alt="Disponible en Google play"/>
								</a>
							</li>
						</ul>
					</section>
				</div>
				<div className="info">
					<a href="https://www.facebook.com/JustEat.es" className="social" tabIndex="74"
					   target="_blank">
						<img src="images/icons/social-facebook.svg"/>
					</a>
					<a href="https://twitter.com/JustEat_es" className="social" tabIndex="77"
					   target="_blank">
						<img src="images/icons/social-twittter.svg"/>
					</a>
					<a href="http://just-eat.es/blog" className="social" tabIndex="76"
					   target="_blank">
						<img src="images/icons/rss-feed.svg"/>
					</a>
					
				</div>
			</React.Fragment>
		);
	}
}

export default FooterES;
