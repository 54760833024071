import React from 'react';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import withTenant from '../../TenantContext/withTenant';
import track from 'react-tracking'
import agent from '../../../agent';
import FeatureToggles from '../../FeatureToggles';

function getCountryCode(tenant) {
	switch(tenant) {
		case 'uk':
			return 'gb';
		default:
			return tenant;
	}
}
class WebToLeadForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			postUrl: '',
			partnerType: ''
		};

		this.setPostUrl = this.setPostUrl.bind(this);
	}

	setPostUrl(url) {
		this.setState({
			postUrl: url
		});
	}

	setPartnerType(partnerType) {
		this.partnerType = partnerType;
		this.props.tracking.trackEvent({eventAction: 'partner_type_dropdown', eventLabel: partnerType})
		this.forceUpdate()
	}

	componentDidMount () {
		agent.Config.get().then(response => {
			if(response) {
				this.setPostUrl(response.webToLeadUrl);
			}
		});
	}

	render() {
		const { t } = this.props;
		const cuisines = t('cuisines', { returnObjects: true });
		const deliveryStatuses = t('deliveryStatuses', { returnObjects: true });
		const driversDropdown = t('driversDropdown', { returnObjects: true });
		const partnerTypes = t('partnerTypes', { returnObjects: true });
		const grocerTypes = t('grocerTypes', { returnObjects: true });
		let cuisinesSection;
		if(this.partnerType === 'Grocery') {
			cuisinesSection = <label className="label">
			<span className="label__text label__text label__text--required">{t('grocerType')}</span>
			<select className="input-select input input--mid input--block"
					id="cuisine"
					name={t('cuisineFieldId')}
					title="Grocer Type"
					required>
				{Object.keys(grocerTypes).map((key) =>
					<option key={key} value={grocerTypes[key]}>{key}</option>
				)}
			</select>
		</label>
		} else
		{
			cuisinesSection = <label className="label">
				<span className="label__text label__text label__text--required">{t('cuisine')}</span>
				<select className="input-select input input--mid input--block"
						id="cuisine"
						name={t('cuisineFieldId')}
						title="Cuisine"
						required>
					{Object.keys(cuisines).map((key) =>
						<option key={key} value={cuisines[key]}>{key}</option>
					)}
				</select>
			</label>
		}
		return (
			<form style={{maxWidth: '500px', margin: '0px auto'}}
				  id="myform"
				  method="post"
				  name="myform"
				  action= { this.state.postUrl }>

				<input type="hidden" name="oid" value={t('organisationId')}/>
				<input type="hidden" id="lead_source" name="lead_source" value="Web"/>
				<input type="hidden" name="retURL" value={window.location.origin + '/thankyou'}/>
				<input type="hidden" name="country" value={t('countryCode')}/>
				<input type="hidden" name="currency" value={t('currencyCode')}/>
				<input type="hidden" name="Campaign_ID" value={t('campaignId')}/>
				<input type="hidden" name="member_status" value="Web enquiry"/>
				<input type="hidden" name="User_Country_Allocation__c" value="COM" />

				<FeatureToggles.On  feature="Groceries">
				<label className="label">
					<span className="label__text label__text label__text--required">{t('partnerType')}</span>
					<select className="input-select input input--mid input--block"
							onChange={(e) => this.setPartnerType(e.target.value)}
							id="partnerType"
							name={t('partnerTypeId')}
							title="PartnerType"
							required>
						<option value="" disabled selected>{t('partnerTypePlaceholder')}</option>
						{Object.keys(partnerTypes).map((key) =>
							<option key={key} value={key}>{partnerTypes[key]}</option>
						)}
					</select>
				</label>
				</FeatureToggles.On>

				<label className="label">
					<span className="label__text label__text label__text--required">{t('firstName')}</span>
					<input className="input input--mid input--block"
						   id="first_name"
						   maxLength="40"
						   name="first_name"
						   type="text"
						   required/>
				</label>
				<label className="label">
					<span className="label__text label__text label__text--required">{t('lastName')}</span>
					<input className="input input--mid input--block"
							id="last_name"
							maxLength="80"
							name="last_name"
							type="text"
							required/>
				</label>

				<label className="label">
				<span className='label__text label__text--required'>{t('mobileNumber')}</span>
			<PhoneInput
				country={ getCountryCode(this.props.tenant) }
				autoFormat={ false }
				countryCodeEditable={ false }
				disableCountryGuess={ true }
				enableSearch={ true }
				placeholder=''
				inputClass='input-phone'
				dropdownClass='country-dropdown-list'
				inputProps={{
					id:"phone",
					pattern:".{5,}",
					maxLength:'40',
					name:t('phoneFieldId'),
					type:"tel",
					required: true }}
			/>
			</label>
				<label className="label">
					<span className="label__text label__text label__text--required">{t('email')}</span>
					<input className="input input--mid input--block"
						   id="email"
						   maxLength="80"
						   name="email"
						   type="email"
						   required/>
				</label>


				<label className="label">
					<span className="label__text label__text label__text--required">{t('restaurantName')}</span>
					<input className="input input--mid input--block"
						   id="company"
						   maxLength="40"
						   name={t('companyFieldId')}
						   type="text"
						   required/>
				</label>


				<label className="label">
					<span className="label__text label__text label__text--required">{t('street')}</span>
					<input className="input input--mid input--block"
						   id="street"
						   name={t('streetFieldId')}
						   type="text"
						   required/>
				</label>


				<label className="label">
					<span className="label__text label__text label__text--required">{t('city')}</span>
					<input className="input input--mid input--block"
						   id="city"
						   maxLength="40"
						   name={t('cityFieldId')}
						   type="text"
						   required/>
				</label>

				<label className="label">
					<span className="label__text label__text label__text--required">{t('zip')}</span>
					<input className="input input--mid input--block"
						   id="zip"
						   maxLength="20"
						   name={t('zipFieldId')}
						   type="text"
						   required/>
				</label>
				
				{cuisinesSection}

				<label className="label">
					<span className="label__text label__text label__text--required">{t('deliveryStatus')}</span>
					<select className="input-select input input--mid input--block"
							id="deliveryStatus"
							name={t('deliveryFieldId')}
							title={t('deliveryStatus')}
							required>
						{Object.keys(deliveryStatuses).map((key) =>
							<option key={key} value={deliveryStatuses[key]}>{key}</option>
						)}
					</select>
				</label>

				<label className="label">
					<span className="label__text label__text label__text--required">{t('drivers')}</span>
					<select className="input-select input input--mid input--block"
							id="drivers"
							name={t('driversFieldId')}
							title={t('drivers')}>
						{Object.keys(driversDropdown).map((key) =>
							<option key={key} value={driversDropdown[key]}>{key}</option>
						)}
					</select>
				</label>

				<label className="label label__center label--extra-margin">
					<input type="submit"
						value={t('submit')}
						className="btn__medium--primary btn--wide" />
				</label>
			</form>

		);
	}
}

export default track({event: 'trackEvent', eventCategory: 'engagement'})(withTenant(WebToLeadForm));