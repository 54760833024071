import React from 'react';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import validation from "../../../../helpers/validation";
import withTenant from '../../../TenantContext/withTenant';

function getCountryCode(tenant) {
	switch(tenant) {
		case 'uk': 
			return 'gb';
		default: 
			return tenant;
	}
}

export default  withTenant(props => {
	const { t, onChange, validationErrors } = props;
	const error = validation.fieldHasErrors("owner.phoneNumber", validationErrors);

	let lblCls = 'label';
	if (error) lblCls += ' error';
	return (
		<label className={lblCls}>
			<span className='label__text label__text--required'>{t('ownerTelephoneText')}</span>
			<PhoneInput
				country={ getCountryCode(props.tenant) }
				autoFormat={ false }
				countryCodeEditable={ false }
				disableCountryGuess={ true }

				enableSearch={ true }
				placeholder=''
				onChange={(value, country, e, formattedValue) => onChange('owner.phoneNumber', formattedValue)}
				inputClass='input-phone'
				dropdownClass='country-dropdown-list'
				inputProps={{ name: 'owner.phoneNumber', required: true, pattern:'.{5,}' }}
			/>
		</label>
	);
});
