//TODO improve this its pretty bad...
import initExperiments from './experiments'

const defaultToggles = {
    'Wizard': false,
    'DocumentUpload': false,
    'Groceries': false
};

const allOn = {
    'Wizard': true,
    'DocumentUpload': true,
    'Groceries': true
};
const allOff = {
    'Wizard': false,
    'DocumentUpload': false,
    'Groceries': false
};

const allOffWithGroceries = {
    'Wizard': false,
    'DocumentUpload': false,
    'Groceries': true
};

const enforcements = {
    ie: allOffWithGroceries,
    it: allOn,
    es: allOn,
    ca: allOff,
    dk: allOffWithGroceries,
    fr: allOff,
    no: allOff,
    uk: {
        'Wizard': true
    }
};

function getTogglesFromUrl() {
    let toggles = {};
    let reset = false;

    try {
        let urlParams = new URLSearchParams(window.location.search);
        for (let key of urlParams.keys()) {
            if (key.startsWith('feature.')) {
                let newKey = key.replace('feature.', '');
                toggles[newKey] = urlParams.get(key) === 'true';
            }
        }

        if (urlParams.get('features-reset') !== null) {
            reset = true
        }
    }
    catch (e) {
        console.warn(e);
    }

    return { toggles, reset };
}

export default async function createToggles() {
    const experiments = await initExperiments();
    return {
        forTenant(tenant) {
            let storage = {};

            let fromUrl = getTogglesFromUrl();

            if (!fromUrl.reset) {
                storage = JSON.parse(sessionStorage.getItem('toggles-' + tenant) || '{}')
            }

            const storedToggles = { ...storage, ...fromUrl.toggles };
            sessionStorage.setItem('toggles-' + tenant, JSON.stringify(storedToggles));

            return {
                ...defaultToggles,
                ...experiments.getToggles(tenant),
                ...storage,
                ...fromUrl.toggles,
                ...enforcements[tenant]
            };
        }
    };
}
