import React, {Component} from 'react';


class FooterIT extends Component {
	render() {
		return (
			<React.Fragment>
				<div className="links">
					<section>
						<input type="checkbox" id="toggle-cucine"/>
						<label htmlFor="toggle-cucine">
							<h3>
								Cucine <svg>
								<use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-arrow"/>
							</svg>
							</h3>
						</label>
						<ul>
							<li><a href="//www.justeat.it/domicilio/pizza/">Pizza a domicilio</a></li>
							<li><a href="//www.justeat.it/domicilio/cinese/">Cinese a domicilio</a></li>
							<li><a href="//www.justeat.it/domicilio/sushi/">Sushi a domicilio</a></li>
							<li><a href="//www.justeat.it/domicilio/kebab/">Kebab a domicilio</a></li>
							<li><a href="//www.justeat.it/domicilio/tipi-cucina/">Tutti i tipi di cucine</a></li>
						</ul>
					</section>
					<section>
						<input type="checkbox" id="toggle-citta"/>
						<label htmlFor="toggle-citta">
							<h3>
								Città <svg>
								<use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-arrow"/>
							</svg>
							</h3>
						</label>
						<ul>
							<li><a href="//www.justeat.it/domicilio/palermo/">Palermo</a></li>
							<li><a href="//www.justeat.it/domicilio/roma/">Roma</a></li>
							<li><a href="//www.justeat.it/domicilio/milano/">Milano</a></li>
							<li><a href="//www.justeat.it/domicilio/napoli/">Napoli</a></li>
							<li><a href="//www.justeat.it/domicilio/Torino/">Torino</a></li>
							<li><a href="//www.justeat.it/domicilio/bologna/">Bologna</a></li>
							<li><a href="//www.justeat.it/domicilio/ferrara/">Ferrara</a></li>
							<li><a href="//www.justeat.it/domicilio/genova/">Genova</a></li>
							<li><a href="//www.justeat.it/domicilio/padova/">Padova</a></li>
							<li><a href="//www.justeat.it/domicilio/parma/">Parma</a></li>
							<li><a href="//www.justeat.it/domicilio/citta/">Tutte le città</a></li>
							<li><a href="//www.justeat.it/domicilio/citta-offerte/">Offerte e Sconti</a></li>
						</ul>
					</section>
					<section>
						<input type="checkbox" id="toggle-whoarewe"/>
						<label htmlFor="toggle-whoarewe">
							<h3>
								Chi siamo <svg>
								<use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-arrow"/>
							</svg>
							</h3>
						</label>
						<ul>
							<li><a href="//www.justeat.it/privacy-policy" target="">Termini e Condizioni</a></li>
							<li><a href="//www.justeat.it/cookiespolicy/" target="">Cookie Policy</a></li>
							<li><a href="//www.justeat.it/about" target="">Informazioni su Just Eat</a></li>
							<li><a href="//www.justeat.it/help" target="">Domande frequenti</a></li>
							<li><a href="//www.justeat.it/domicilio/miglior-prezzo-garantito" target="">Miglior Prezzo
								Garantito</a></li>
							<li><a href="//www.justeat.it/suggest-a-restaurant" target="">Suggerisci un ristorante</a>
							</li>
							<li><a href="//www.justeat.it/jobs" target="">Lavora con noi</a></li>
							<li><a href="//partner.justeat.it/" target="">Partner Center</a></li>
							<li><a href="//www.justeat.it/blog" target="">Blog</a></li>
							<li><a href="//www.justeat.it/informazioni/gestione-dei-reclami" target="">Gestione dei reclami</a></li>
						</ul>
					</section>
					<section>
						<input type="checkbox" id="toggle-app"/>
						<label htmlFor="toggle-app">
							<h3>
								App <svg>
								<use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-arrow"/>
							</svg>
							</h3>
						</label>
						<ul className="app-icons">
							<li>
								<a href="//app.adjust.com/6zamp7?utm_medium=internal&amp;campaign=footer"
								   target="_blank">
									<img
										src="https://d3fpaxu9zxkgws.cloudfront.net/assets/dist/img/icons/appstore/ios-v2.it.svg"
										alt="Scarica su App Store"/>
								</a>
							</li>
							<li>
								<a href="//app.adjust.com/60f9v1?utm_medium=internal&amp;campaign=footer"
								   target="_blank">
									<img
										src="https://d3fpaxu9zxkgws.cloudfront.net/assets/dist/img/icons/appstore/android-v2.it.svg"
										alt="Disponibile su Google Play"/>
								</a>
							</li>
						</ul>
					</section>
				</div>
				<div className="info">
					<a href="//www.facebook.com/justeat" className="social" tabIndex="74" target="_blank">
						<img src="images/icons/social-facebook.svg"/>
					</a>
					<a href="//www.twitter.com/justeatuk" className="social" tabIndex="77" target="_blank">
						<img src="images/icons/social-twittter.svg"/>
					</a>
					<a href="//www.just-eat.co.uk/blog/feed" className="social" tabIndex="76" target="_blank">
						<img src="images/icons/rss-feed.svg"/>
					</a>
					
				</div>
			</React.Fragment>
		);
	}
}

export default FooterIT;
