import React from 'react';
import Footer from "../Footer";

class ComplaintHandling extends React.Component {

    render() {
        return (
        <React.Fragment>
        <div className="complaint">
            <div className="complaint__container">
                <div className="complaint__breadcrumb">
                    <div className="complaint__breadcrumb--container">
                        <span className="complaint__breadcrumb--item"><a href="/">Home</a></span> 
                        <span className="complaint__breadcrumb--separator icon-Chevron-Right"></span>
                        <span className="complaint__breadcrumb--item">Complaint Handling</span> 
                    </div>
                </div>
                <div className="complaint__content">
                    <h1>Complaint-handling</h1>
                    <p>
                    We operate a complaint-handling system that you can access and use free of charge. It allows you to submit complaints to us across a range of issues, and we will deal with any complaints transparently, equally and proportionately.
                    </p>
                    <p>You can submit a complaint to us:</p>
                    <ul>
                        <li>through the Partner Centre (which has certain options you can select for the type of complaint or query you may have) - https://partner.just-eat.ie/; and/or</li>
                        <li>through our contact centre helpline - 0344 243 7777. The contact centre is open between 07.00 to 00.30 on Sunday to Wednesday, and 07.00 to 02.00 on Thursday to Saturday (though these hours are subject to change).</li>
                    </ul>
                    <p>All complaints will be dealt with appropriately by the person receiving them, and as part of that may then be escalated internally so that the appropriate Just Eat internal team can then consider it and respond to you (for example, we have internal compliance and legal teams that deal with certain types of complaint). We will respond to all complaints as soon as we reasonably can.</p>
                    <h1>Functioning and effectiveness</h1>
                    <p>If you would like information on the functioning and effectiveness of our complaint-handling system, you can contact us via the contact channels mentioned above and we will send it to you.</p>
                </div>
            </div>
        </div>
        <Footer />
        </React.Fragment>);
    };
}


export default ComplaintHandling;