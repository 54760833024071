import React from 'react';
import BasicWizardSelect from "./BasicWizardSelect";
import FeatureToggles from '../../../FeatureToggles';
import track from 'react-tracking'
import withTenant from '../../../TenantContext/withTenant';

class BusinessTypeField extends React.Component  {
	constructor(props) {
		super(props);

		this.handleInputChange = this.handleInputChange.bind(this);
	}

	handleInputChange(key, value) {
		const businessType = value;
		this.props.tracking.trackEvent({eventAction: 'partner_type_dropdown', eventLabel: businessType})
		this.props.onChange(key, value);
	}

	render() {
		const { t } = this.props;
		return (
			<FeatureToggles.On  feature="Groceries">
				<BasicWizardSelect value=''
								validationErrors={this.props.validationErrors}
								name="businessType"
								onChange={this.handleInputChange}
								required={true}
								placeholder={t('businessTypeText')}
								title={t('businessTypeText')}
								options={t('businessTypeOptions', {returnObjects: true})}
				/>
			</FeatureToggles.On>
		);
	}
};

export default track({event: 'trackEvent', eventCategory: 'engagement'})(withTenant(BusinessTypeField));

