import React from 'react';
import BitmapInfo from "../../Shared/BitmapInfo";

class StepsSection extends React.Component {
	render() {
		const { t } = this.props;

		return (
			<section id="steps" className="section section--white">
				<div className="l-container">
					<h2 className="section__title">
						{t('stepsTitle')}
					</h2>

					<p className="section__sub-title">
						{t('stepsText')}
					</p>

					<div className="bitmap-info-list">
						<BitmapInfo
							image="/images/icons/signup.svg"
							title={t('stepsSignupTitle')}
						>
							{t('stepsSignupText')}
						</BitmapInfo>

						<BitmapInfo
							image="/images/icons/welcome-pack.svg"
							title={t('stepsWelcomePackTitle')}
						>
							{t('stepsWelcomePackText')}
						</BitmapInfo>

						<BitmapInfo
							image="/images/icons/start-orders.svg"
							title={t('stepsOrdersTitle')}
						>
							{t('stepsOrdersText')}
						</BitmapInfo>
					</div>
				</div>
			</section>
		);
	}
}

export default StepsSection;
